import React, { useEffect, useState } from 'react';
import { Box, Tabs, Tab, Divider, Typography, IconButton } from '@mui/material';
import TicketingOverviewCurrent from "./TicketingOverviewCurrent";
import TicketingOverviewUpcoming from "./TicketingOverviewUpcoming";
import TicketingOverviewPast from "./TicketingOverviewPast";
import QrCodeIcon from "@mui/icons-material/QrCode";
import QRDialog from './QRDialog';

const TicketingOverview = () => {
    const [value, setValue] = useState(0);
    const [openQRScanner, setOpenQRScanner] = useState(false);

    useEffect(() => {
        const hash = window.location.hash;
        if (hash === '#public-details') {
            setValue(2);
        }
    }, []);

    const handleTabChange = (event, newValue) => {
        setValue(newValue);
    };

    const handleQRIconClick = () => {
        setOpenQRScanner(true);
    };

    const handleCloseQRScanner = () => {
        setOpenQRScanner(false);
    };

    return (
        <Box sx={{ display:'flex', padding:{xs:1,md:'32px'} }}>
            <Box flex={1}>
                <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-start', alignSelf: 'stretch', width: '100%'}}>
                    <Box>
                        <Typography variant='h1'>Ticketing Overview</Typography>
                        <Typography variant='h2'>All of your event tickets and pricing.</Typography>
                    </Box>
                    <Box sx={{ ml: 'auto', mr:'32px', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                        <Typography variant="body2" align="center">
                            Click here to scan<br />customer tickets
                        </Typography>
                        <IconButton aria-label="qr" size="large" onClick={handleQRIconClick}>
                            <QrCodeIcon sx={{ fontSize: 48 }} />
                        </IconButton>
                    </Box>
                </Box>

                <Box height='38px' paddingTop='32px'>
                    <Tabs value={value} onChange={handleTabChange} aria-label="service tabs">
                        <Tab label="Current" />
                        <Tab label="Upcoming" />
                        <Tab label="Past" />
                    </Tabs>
                </Box>

                <Divider sx={{margin: '41px 0 32px 0'}}/>

                <Box>
                    { value === 0 && <TicketingOverviewCurrent/> }
                    { value === 1 && <TicketingOverviewUpcoming/> }
                    { value === 2 && <TicketingOverviewPast/> }
                </Box>
            </Box>

            <QRDialog open={openQRScanner} onClose={handleCloseQRScanner} />
        </Box>
    );
};

export default TicketingOverview;