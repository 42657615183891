import React, { useState, useEffect } from "react";
import { Box, Divider, TextField, Typography, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";
import { styled } from "@mui/system";
import { useNavigate, useLocation } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import {useDispatch, useSelector} from 'react-redux';
import { createProduct, updateProduct } from "../../../../../redux/productSlice";
import axios from "axios";
import keycloak from "../../../../../keycloak";
import SharedSelect from "../../../../shared/SharedSelect";
import {
    createProductCategory,
    fetchProductCategories,
    clearNewlyCreatedCategory
} from "../../../../../redux/productCategorySlice";

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const TextFieldTitleCategory = styled(Typography)(({ theme }) => ({
    color: 'var(--Gray-700, #344054)',
    fontFamily: 'Inter',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: '20px',
    width: '250px',
}));

const TextFieldTitle = styled(Typography)(({ theme }) => ({
    color: 'var(--Gray-700, #344054)',
    fontFamily: 'Inter',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: '20px',
    width: '312px',
}));

const StyledSharedSelect = styled(SharedSelect)(({ theme }) => ({
    width: '50%',
    paddingRight: { xs: '0px', sm: '0px', md: '0px', lg: '0px' },
}));

const CustomizeProduct = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const dispatch = useDispatch();
    const product = location.state?.product || {};
    const { profiles, status, error } = useSelector((state) => state.organizationProfiles);
    const [initialized, setInitialized] = useState(false);
    const [organization, setOrganization] = useState(null);
    const [idle, setIdle] = useState(true);
    const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'info' });
    const [selectedCategoryId, setSelectedCategoryId] = useState('');


    const { control, handleSubmit, formState: { errors }, setValue } = useForm({
        defaultValues: {
            id: product.id || '',
            title: product.name || '',
            category: product.product_category_id || '',  //not yet existed in database
            price: product.price || '',
            description: product.description || '',
        }
    });

    const [loading, setLoading] = useState(false);
    const [successDialogOpen, setSuccessDialogOpen] = useState(false);

    useEffect(() => {
        // Set the category ID when the component mounts or when the product changes
        if (product.product_category_id) {
            setSelectedCategoryId(product.product_category_id);
            setValue('category', product.product_category_id);
        }
    }, [product, setValue]);

    const handleCategoryChange = (categoryId) => {
        setSelectedCategoryId(categoryId);
        // You can perform any additional actions here when a category is selected
    };

    useEffect(() => {
        const initKeycloak = async () => {
            try {
                // await keycloak.init({ onLoad: 'login-required' });
                if (keycloak.authenticated) {
                    const token = keycloak.tokenParsed;
                    const org = token?.organization;
                    setOrganization(org);
                }
            } catch (error) {
                console.error("Keycloak initialization failed:", error);
                setSnackbar({
                    open: true,
                    message: 'Failed to initialize authentication. Please try again later.',
                    severity: 'error'
                });
            } finally {
                setIdle(false);
            }
        };

        initKeycloak();
    }, []);

    // useEffect(() => {
    //     if (status === 'idle') {
    //         try {
    //             const token = keycloak.tokenParsed;
    //             const org = token?.organization;
    //             setOrganization(org);
    //         } catch (error) {
    //             console.error("Error parsing token:", error);
    //             setSnackbar({
    //                 open: true,
    //                 message: 'Failed to retrieve organization information. Please try again later.',
    //                 severity: 'error'
    //             });
    //         }
    //     }
    // }, [status, dispatch]);

    const onSubmit = async (data) => {
        setLoading(true);

        const postData = {
            name: data.title,
            product_category_id: selectedCategoryId,
            price: data.price,
            minimum_pax: 0,
            maximum_pax: 0,
            minimum_amount: parseFloat(data.price),
            maximum_amount: parseFloat(data.price),
            description: data.description,
            user_organization_id: 1,
            user_role_id: 3,
            categoryId: 2,
            minimumCurrencyId: 2,
            maximumCurrencyId: 2,
            statusOwnershipId: 15,
        };

        try {
            if (data.id) {
                await dispatch(updateProduct({ id: data.id, postData: postData })).unwrap();
            } else {
                await dispatch(createProduct(postData)).unwrap();
            }
            setSuccessDialogOpen(true);
        } catch (error) {
            console.error("There was an error creating/updating the product:", error);
        } finally {
            setLoading(false);
        }
    };

    const handleDialogClose = () => {
        setSuccessDialogOpen(false);
        navigate('/settings/product-catalog');
    };

    return (
        <Box>
            <form noValidate autoComplete="off" onSubmit={handleSubmit(onSubmit)}>

                <Box display="flex" alignItems="center" mb={4}>
                    <TextFieldTitleCategory>Product Category</TextFieldTitleCategory>
                    <Box flexGrow={1}>
                        <StyledSharedSelect
                            label="Product Category"
                            value={selectedCategoryId}
                            onChange={handleCategoryChange}
                            options={[
                                { name: 'name', label: 'Category Name' },
                            ]}
                            createNewOption={createProductCategory}
                            fetchOptions={fetchProductCategories}
                            selectSelector={(state) => state.productCategories.productCategories}
                            createSelector={(state) => state.productCategories.newlyCreatedCategory}
                            clearNewlyCreatedSelector={clearNewlyCreatedCategory}
                            errorSelector={(state) => state.productCategories.error}
                        />
                    </Box>
                </Box>

                <Box display="flex">
                    <TextFieldTitle>Product Name</TextFieldTitle>
                    <Controller
                        name="title"
                        control={control}
                        rules={{ required: "Product name is required" }}
                        render={({ field }) => (
                            <TextField
                                {...field}
                                label="Enter the name for product"
                                fullWidth
                                error={!!errors.title}
                                helperText={errors.title?.message}
                                sx={{ paddingRight: { xs: '0px', sm: '20px', md: '50px', lg: '240px' }}}
                            />
                        )}
                    />
                </Box>

                <Divider />

                <Box display="flex">
                    <TextFieldTitle>Product Price</TextFieldTitle>
                    <Controller
                        name="price"
                        control={control}
                        rules={{
                            required: "Price is required",
                            validate: {
                                positive: value => parseFloat(value) > 0 || "Please enter a valid price. Use numbers only (e.g., 59.99)",
                                float: value => /^[+-]?\d+(\.\d+)?$/.test(value) || "Please enter a valid price. Use numbers only (e.g., 59.99)"
                            }
                        }}
                        render={({ field }) => (
                            <TextField
                                {...field}
                                label="Enter the pricing for product"
                                fullWidth
                                error={!!errors.price}
                                helperText={errors.price?.message}
                                inputProps={{
                                    inputMode: 'decimal',
                                    pattern: '[0-9.]*',
                                    step: '0.01',
                                }}
                                onChange={(e) => {
                                    const value = e.target.value;
                                    if (/^\d*\.?\d*$/.test(value)) {
                                        field.onChange(value);
                                    }
                                }}
                                sx={{ paddingRight: { xs: '0px', sm: '20px', md: '50px', lg: '240px' }}}
                            />
                        )}
                    />
                </Box>

                <Divider />

                <Box display="flex">
                    <TextFieldTitle>Description for Product</TextFieldTitle>
                    <Controller
                        name="description"
                        control={control}
                        render={({ field }) => (
                            <TextField
                                {...field}
                                label="Enter the description for product"
                                fullWidth
                                multiline
                                rows={7}
                                inputProps={{ maxLength: 250 }}
                                helperText={`${field.value.length}/250`}
                                sx={{
                                    '& .MuiOutlinedInput-root': {
                                        padding: '15px',
                                        '& .MuiOutlinedInput-input': {
                                            padding: 0,
                                        },
                                    },
                                    paddingRight: { xs: '0px', sm: '20px', md: '50px', lg: '240px' }
                                }}
                            />
                        )}
                    />
                </Box>

                <Divider />

                <Box sx={{ marginTop: '16px' }}>
                    <Button
                        variant="save"
                        type="submit"
                        disabled={loading}
                    >
                        {loading ? 'Saving...' : product.id ? 'Update Product' : 'Create Product'}
                    </Button>
                </Box>
            </form>

            <Dialog
                open={successDialogOpen}
                onClose={handleDialogClose}
            >
                <DialogTitle>Success</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        The product has been {product.id ? 'updated' : 'created'} successfully.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleDialogClose} variant='save' autoFocus sx={{margin:'0 18px 8px 0'}}>
                        OK
                    </Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
};

export default CustomizeProduct;