import React, {useCallback, useEffect, useMemo, useState} from 'react';
import axios from 'axios';
import {Box, Paper, Skeleton, TextField, Typography,} from '@mui/material';
import {DataGrid, GridToolbar} from '@mui/x-data-grid';
import _ from 'lodash'; // For debounce
import keycloak from "../../../keycloak";
import BookingDetailsModal from "./actions/BookingDetailsModal";
import BookingList from "./actions/BookingList";
import ChangeDateDialog from "./actions/ChangeDate";
import GetBooking from "./actions/GetBooking";

const SkeletonRow = () => (
    <Box sx={{ display: 'flex', alignItems: 'center', height: 52, px: 2 }}>
        <Skeleton variant="rectangular" width="20%" height={20} sx={{ mr: 2 }} />
        <Skeleton variant="rectangular" width="30%" height={20} sx={{ mr: 2 }} />
        <Skeleton variant="rectangular" width="15%" height={20} sx={{ mr: 2 }} />
        <Skeleton variant="rectangular" width="15%" height={20} sx={{ mr: 2 }} />
        <Skeleton variant="circular" width={24} height={24} sx={{ mr: 1 }} />
        <Skeleton variant="circular" width={24} height={24} />
    </Box>
);

const TicketingOverviewUpcoming = () => {
    const API_BASE_URL = 'https://api-dev.eventlah.com/api';
    const [organizationId, setOrganizationId] = useState(1);
    const [searchQuery, setSearchQuery] = useState('');
    const [paginationModel, setPaginationModel] = useState({
        page: 0,
        pageSize: 10,
    });
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [ticketData, setTicketData] = useState([]);
    const [totalRows, setTotalRows] = useState(0);

    const fetchTicketData = useCallback(async () => {
        setLoading(true);
        setError(null);
        try {
            const response = await axios.get(`${API_BASE_URL}/purchases`, {
                params: {
                    page: paginationModel.page + 1,
                    pageSize: paginationModel.pageSize,
                    search: searchQuery,
                    organization_id: organizationId,
                    purchase_type: 1,
                    timeframe: 'upcoming'
                },
                headers: {
                    Authorization: `Bearer ${keycloak.token}`
                }
            });

            if (response.data.code === 200 && response.data.data && Array.isArray(response.data.data.purchases)) {
                setTicketData(response.data.data.purchases);
                setTotalRows(response.data.data.pagination?.totalCount || 0);
            } else {
                throw new Error(response.data.message || 'Invalid data structure received from the server');
            }
        } catch (err) {
            setError('Failed to fetch ticket data: ' + (err.message || 'Unknown error'));
            console.error('Error fetching ticket data:', err);
            setTicketData([]);
        } finally {
            setLoading(false);
        }
    }, [paginationModel.page, paginationModel.pageSize, searchQuery, organizationId]);

    useEffect(() => {
        fetchTicketData();
    }, [fetchTicketData]);

    const handleSearchChange = (event) => {
        const value = event.target.value;
        setSearchQuery(value); // Update the local state immediately
        debounceSearch(value); // Debounce the API call
    };

    const debounceSearch = useMemo(
        () => _.debounce((value) => {
            setSearchQuery(value);
            setPaginationModel(prev => ({ ...prev, page: 0 }));
        }, 300),
        []
    );

    const handlePaginationModelChange = useCallback((newModel) => {
        setPaginationModel(newModel);
    }, []);

    const columns = useMemo(() => [
        { field: 'id', headerName: 'ID', width: 70 },
        {
            field: 'event',
            headerName: 'Event Name',
            width: 150,
            valueGetter: (params) => {
                return params ? params.name : 'No event';
            },
        },
        {
            field: 'daily_event',
            headerName: 'Event Date',
            width: 180,
            valueGetter: (params) => {
                return params?.event_date
                    ? new Date(params.event_date).toLocaleDateString()
                    : 'No daily event';
            },
        },
        {
            field: 'customer',
            headerName: 'Customer Name',
            width: 200,
            valueGetter: (params) => {
                const customer = params;
                if (customer) {
                    const firstName = customer.first_name?.trim() || '';
                    const lastName = customer.last_name?.trim() || '';
                    const fullName = `${firstName} ${lastName}`.trim();
                    return fullName.length > 0 ? fullName : 'N/A';
                }
                return 'N/A';
            },
        },
        { field: 'quantity', headerName: 'Quantity', width: 100 },
        {
            field: 'total_amount',
            headerName: 'Total Amount',
            width: 130,
            valueFormatter: (params) => {
                const value = params;
                return value && !isNaN(value) ? `RM ${parseFloat(value).toFixed(2)}` : 'N/A';
            },
        },
        {
            field: 'created_at',
            headerName: 'Purchase Date',
            width: 180,
            valueFormatter: (params) => {
                const createdAt = params;
                return createdAt ? new Date(createdAt).toLocaleString() : 'N/A';
            },
        },
        {
            field: 'agent',
            headerName: 'Agent Name',
            width: 180,
            valueGetter: (params) => {
                const agent = params;
                if (!agent) return '';

                const fullName = `${agent.first_name || ''} ${agent.last_name || ''}`.trim();
                const phoneNumber = agent.phone_number ? ` (${agent.phone_number})` : '';

                return `${fullName}${phoneNumber}`;
            },
        },
        {
            field: 'actions',
            headerName: 'Actions',
            flex: 1.3,
            renderCell: (params) => (
                <Box sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', height: '100%', width: '100%', paddingLeft: '8px' }}>
                    <BookingDetailsModal id={params.row.id} />
                    <BookingList id={params.row.id} />
                    <ChangeDateDialog
                        orderId={params.row.id}
                        currentBookingDate={params.row.bookingDate}
                    />
                    <GetBooking
                        orderId={params.row.id}
                        bookingDetails={params.row.bookingDetails}
                    />
                </Box>
            )
        }
    ], []);

    return (
        <Box>
            <Box>
                <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
                    <Box/>
                    <Box display="flex" gap="12px">
                        <TextField
                            variant="outlined"
                            placeholder="Search by Ticket Name"
                            value={searchQuery}
                            onChange={handleSearchChange}
                            size="small"
                            sx={{
                                '& .MuiOutlinedInput-root': {
                                    height: '34px',
                                    '& input': {
                                        height: '34px',
                                        padding: '0 14px',
                                    },
                                    '& .MuiInputBase-input': {
                                        height: '34px',
                                        padding: '0 14px',
                                    }
                                }
                            }}
                        />
                    </Box>
                </Box>

                <Paper
                    sx={{
                        width: "100%",
                        borderRadius: "8px",
                        marginTop: '24px',
                        border: "1px solid var(--Gray-200, #EAECF0)",
                        background: "var(--White, #FFF)",
                        boxShadow: "0px 1px 3px 0px rgba(16, 24, 40, 0.10), 0px 1px 2px 0px rgba(16, 24, 40, 0.06)",
                    }}
                >
                    {loading ? (
                        <Box>
                            <Box sx={{ px: 2, py: 1 }}>
                                <Skeleton variant="rectangular" height={52} />
                            </Box>
                            {[...Array(5)].map((_, index) => (
                                <SkeletonRow key={index} />
                            ))}
                        </Box>
                    ) : error ? (
                        <Typography color="error" sx={{ p: 2 }}>{error}</Typography>
                    ) : (
                        <DataGrid
                            slots={{ toolbar: GridToolbar }}
                            rows={ticketData || []}
                            columns={columns}
                            paginationModel={paginationModel}
                            onPaginationModelChange={handlePaginationModelChange}
                            pageSizeOptions={[5, 10, 20]}
                            rowCount={totalRows}
                            paginationMode="server"
                            checkboxSelection
                            disableRowSelectionOnClick
                            autoHeight
                            rowHeight={70}
                            initialState={{
                                pagination: {
                                    paginationModel: {
                                        pageSize: 10,
                                    },
                                },
                            }}
                            getRowId={(row) => row.id || Math.random()}
                            sx={{
                                '& .MuiDataGrid-toolbarContainer .MuiButtonBase-root': {
                                    backgroundColor: '#737373',
                                    color: '#fff',
                                    height: '34px',
                                    my: 1,
                                    minWidth: 'auto',
                                    '&:hover': {
                                        backgroundColor: '#404040',
                                    },
                                },
                            }}
                        />
                    )}
                </Paper>
            </Box>
        </Box>
    );
};

export default TicketingOverviewUpcoming;