import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    TextField,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    Box
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import CircularProgress from "@mui/material/CircularProgress";

const SharedSelect = ({
                          label,
                          value,
                          onChange,
                          options,
                          createNewOption,
                          fetchOptions,
                          selectSelector,
                          createSelector,
                          errorSelector,
                          clearNewlyCreatedSelector
                      }) => {
    const dispatch = useDispatch();
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [newItemData, setNewItemData] = useState({});

    const selectedOptions = useSelector(selectSelector) || [];
    const newlyCreatedItem = useSelector(createSelector);
    const error = useSelector(errorSelector);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        if (fetchOptions) {
            setIsLoading(true);
            dispatch(fetchOptions())
                .then(() => setIsLoading(false))
                .catch((error) => {
                    setIsLoading(false);
                    console.error("Error fetching options:", error);
                });
        } else {
            setIsLoading(false);
        }
    }, [dispatch, fetchOptions, label]); // Add any other dependencies that should trigger a refetch

    useEffect(() => {
        if (newlyCreatedItem) {
            onChange(newlyCreatedItem.id);
            setIsDialogOpen(false);
            dispatch(clearNewlyCreatedSelector());
        }
    }, [newlyCreatedItem, onChange, dispatch, clearNewlyCreatedSelector]);

    const handleOpenDialog = () => {
        setIsDialogOpen(true);
    };

    const handleCloseDialog = () => {
        setIsDialogOpen(false);
        setNewItemData({});
    };

    const handleCreateNewItem = () => {
        dispatch(createNewOption(newItemData));
    };

    const handleNewItemDataChange = (e) => {
        const { name, value } = e.target;
        setNewItemData(prev => ({ ...prev, [name]: value }));
    };

    if (isLoading) {
        return <CircularProgress />;
    }
    return (
        <FormControl fullWidth>
            <InputLabel>{label}</InputLabel>
            <Select
                value={value}
                onChange={(e) => onChange(e.target.value)}
                label={label}
            >
                {selectedOptions.map((option) => (
                    <MenuItem key={option.id} value={option.id}>
                        {option.name}
                    </MenuItem>
                ))}
                <MenuItem value="create_new" onClick={handleOpenDialog}>
                    <AddIcon /> Create New {label}
                </MenuItem>
            </Select>

            <Dialog open={isDialogOpen} onClose={handleCloseDialog}>
                <DialogTitle>Create New {label}</DialogTitle>
                <DialogContent>
                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, mt: 2 }}>
                        {options.map((option) => (
                            <TextField
                                key={option.name}
                                label={option.label}
                                name={option.name}
                                value={newItemData[option.name] || ''}
                                onChange={handleNewItemDataChange}
                                fullWidth
                            />
                        ))}
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseDialog}>Cancel</Button>
                    <Button onClick={handleCreateNewItem}>Create</Button>
                </DialogActions>
            </Dialog>
        </FormControl>
    );
};

export default SharedSelect;