import React, { useState } from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    IconButton,
    Box,
    Typography,
    Paper,
    Divider, Tooltip,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import ReceiptIcon from '@mui/icons-material/Receipt';
import ConfirmationNumberIcon from '@mui/icons-material/ConfirmationNumber';

const GetBooking = ({ orderId, bookingDetails }) => {
    const [open, setOpen] = useState(false);

    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    // Placeholder data - replace with actual data in a real application
    const placeholder = {
        bookingNumber: 'BK12345',
        paymentDetails: [
            { title: 'Adult Ticket', subtitle: '2x RM50.00', price: 'RM100.00' },
            { title: 'Child Ticket', subtitle: '1x RM25.00', price: 'RM25.00' },
            { title: 'Booking Fee', subtitle: '', price: 'RM5.00' },
        ],
        totalAmount: 'RM130.00',
    };

    const { bookingNumber, paymentDetails, totalAmount } = bookingDetails || placeholder;

    return (
        <>
            <Tooltip title="Get Booking Details">
                <IconButton onClick={handleOpen} aria-label="get-booking">
                    <ReceiptIcon />
                </IconButton>
            </Tooltip>
            <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
                <DialogTitle>
                    Booking Details
                    <IconButton
                        aria-label="close"
                        onClick={handleClose}
                        sx={{ position: 'absolute', right: 8, top: 8 }}
                    >
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                    <Paper
                        elevation={3}
                        sx={{
                            backgroundColor: '#FFD700',
                            color: '#000',
                            borderRadius: '8px',
                            mb: 2,
                            p: 2,
                            boxShadow: '3px 3px 10px rgba(0, 0, 0, 0.2)',
                        }}
                    >
                        <Box
                            sx={{
                                border: '2px dashed #000',
                                p: 2,
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                            }}
                        >
                            <ConfirmationNumberIcon sx={{ fontSize: 40, mb: 2 }} />
                            <Typography variant="h6" fontWeight="bold">
                                Booking Number
                            </Typography>
                            <Typography variant="h5" fontWeight="bold">
                                {bookingNumber}
                            </Typography>
                        </Box>
                    </Paper>

                    <Paper sx={{ backgroundColor: '#f4f4f4', p: 2, borderRadius: '4px' }}>
                        <Typography variant="h6" gutterBottom>Payment Details</Typography>
                        {paymentDetails.map((item, index) => (
                            <Box key={index} sx={{ display: 'flex', justifyContent: 'space-between', py: 1 }}>
                                <Box>
                                    <Typography variant="subtitle1" fontWeight="bold">{item.title}</Typography>
                                    <Typography variant="body2">{item.subtitle}</Typography>
                                </Box>
                                <Typography variant="subtitle1" fontWeight="bold">{item.price}</Typography>
                            </Box>
                        ))}
                        <Divider sx={{ my: 1 }} />
                        <Box sx={{ display: 'flex', justifyContent: 'space-between', py: 1 }}>
                            <Typography variant="h6">Total Amount</Typography>
                            <Typography variant="h6" fontWeight="bold">{totalAmount}</Typography>
                        </Box>
                    </Paper>
                </DialogContent>
            </Dialog>
        </>
    );
};

export default GetBooking;