import React, { useEffect, useMemo, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import {
    Box,
    Button,
    Paper,
    Typography,
    TextField,
    Divider,
    Skeleton,
} from '@mui/material';
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import AddIcon from "@mui/icons-material/Add";
import { fetchPurchases } from '../../../redux/purchasesSlice';

const OrdersList = () => {
    const dispatch = useDispatch();
    const [organizationId, setOrganizationId] = useState(1);
    const [isInitialLoad, setIsInitialLoad] = useState(true);
    const purchasesData = useSelector((state) => state.purchases.purchases);
    const { pagination, status } = useSelector((state) => state.purchases);
    const [searchQuery, setSearchQuery] = useState('');
    const [paginationModel, setPaginationModel] = useState({
        page: 0,
        pageSize: 10
    });
    const [totalCount, setTotalCount] = useState(0);

    useEffect(() => {
        dispatch(fetchPurchases({
            page: paginationModel.page + 1,
            pageSize: paginationModel.pageSize,
            searchQuery: searchQuery,
            organization_id: organizationId,
            purchase_type: 2,
            status_id: 1
        })).then(() => {
            setIsInitialLoad(false);
        });
    }, [paginationModel, searchQuery, dispatch, organizationId]);

    useEffect(() => {
        if (pagination) {
            setTotalCount(pagination.totalCount || 0);
        }
    }, [pagination]);

    const handleSearchChange = (event) => {
        setSearchQuery(event.target.value);
        setPaginationModel({ ...paginationModel, page: 0 });
    };

    const handlePaginationModelChange = (newModel) => {
        setPaginationModel(newModel);
    };

    const rows = useMemo(() => {
        return purchasesData?.map(purchase => ({
            ...purchase,
            id: purchase.id,
            customerName: purchase.customerName,
            productName: purchase.product?.name || 'N/A',
            packageName: `Package ${purchase.package_id}`,
            quantity: purchase.quantity,
            productPrice: purchase.product_price,
            totalAmount: purchase.total_amount,
            purchaseDate: new Date(purchase.created_at).toLocaleDateString(),
        })) || [];
    }, [purchasesData]);

    const columns = [
        { field: 'id', headerName: 'ID', flex: 1, minWidth: 100 },
        { field: 'packageName', headerName: 'Package', flex: 1, minWidth: 120 },
        { field: 'purchaseDate', headerName: 'Purchase Date', flex: 1, minWidth: 150 },
        { field: 'customerName', headerName: 'Customer Name', flex: 1, minWidth: 170 },
        { field: 'productName', headerName: 'Product Name', flex: 1, minWidth: 150 },
        { field: 'quantity', headerName: 'Quantity', flex: 1, minWidth: 130 },
        {
            field: 'productPrice',
            headerName: 'Product Price',
            flex: 1,
            minWidth: 150,
            renderCell: (params) => `RM${params.value}`
        },
        {
            field: 'totalAmount',
            headerName: 'Total Amount',
            flex: 1,
            minWidth: 150,
            renderCell: (params) => `RM${params.value}`
        },
    ];

    const renderSkeletons = () => (
        <Box sx={{padding:'0 32px 24px 32px'}}>
            {[...Array(5)].map((_, index) => (
                <Box key={index} sx={{ display: 'flex', alignItems: 'center', mx: 1, my: 2 }}>
                    <Skeleton variant="rectangular" width={60} height={40} sx={{ mr: 5, borderRadius:'4px' }} />
                    <Box sx={{ width: '100%' }}>
                        <Skeleton variant="text" width="100%" />
                        <Skeleton variant="text" width="100%" />
                    </Box>
                </Box>
            ))}
        </Box>
    );

    return (
        <Box sx={{ display:'flex', padding:{xs:1,md:'32px'} }}>
            <Box flex={1}>
                <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-start', alignSelf: 'stretch', width: '100%'}}>
                    <Box>
                        <Typography variant='h1'>Purchases</Typography>
                        <Typography variant='h2'>View, edit, and manage all customer purchases in one place.</Typography>
                    </Box>
                    <Box sx={{ ml: 'auto', mr:'32px', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                        <Button component={Link} to="/purchases/add-purchase" variant="save" startIcon={<AddIcon />}>
                            Add New Purchase
                        </Button>
                    </Box>
                </Box>

                <Divider/>

                <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
                    <Box/>
                    <Box display="flex" gap="12px">
                        <TextField
                            variant="outlined"
                            placeholder="Search purchases"
                            value={searchQuery}
                            onChange={handleSearchChange}
                            size="small"
                            sx={{
                                '& .MuiOutlinedInput-root': {
                                    height: '34px',
                                    '& input': {
                                        height: '34px',
                                        padding: '0 14px',
                                    },
                                }
                            }}
                        />
                    </Box>
                </Box>

                <Paper sx={{
                    width: "100%",
                    borderRadius: "8px",
                    marginTop: '24px',
                    border: "1px solid var(--Gray-200, #EAECF0)",
                    background: "var(--White, #FFF)",
                    boxShadow: "0px 1px 3px 0px rgba(16, 24, 40, 0.10), 0px 1px 2px 0px rgba(16, 24, 40, 0.06)",
                }}>
                    <Typography sx={{
                        color: "var(--Gray-900, #101828)",
                        fontFamily: "Inter",
                        fontSize: "18px",
                        fontStyle: "normal",
                        fontWeight: 500,
                        lineHeight: "28px",
                        padding: "20px 24px",
                    }}>
                        All Purchases
                    </Typography>

                    {isInitialLoad && status === 'loading' ? (
                        renderSkeletons()
                    ) : (
                        <DataGrid
                            slots={{ toolbar: GridToolbar }}
                            rows={rows}
                            columns={columns}
                            paginationModel={paginationModel}
                            onPaginationModelChange={handlePaginationModelChange}
                            pageSizeOptions={[5, 10, 20]}
                            rowCount={totalCount}
                            paginationMode="server"
                            checkboxSelection
                            disableRowSelectionOnClick
                            rowHeight={70}
                            loading={status === 'loading'}
                            initialState={{
                                pagination: {
                                    paginationModel: {
                                        pageSize: 10,
                                    },
                                },
                            }}
                            sx={{
                                '& .MuiDataGrid-toolbarContainer .MuiButtonBase-root': {
                                    backgroundColor: '#737373',
                                    color: '#fff',
                                    height: '34px',
                                    my:1,
                                    minWidth: 'auto',
                                    '&:hover': {
                                        backgroundColor: '#404040',
                                    },
                                },
                            }}
                        />
                    )}
                </Paper>
            </Box>
        </Box>
    );
};

export default OrdersList;