import React, { useEffect, useMemo, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
    Box,
    Button,
    Paper,
    Typography,
    TextField,
    Divider,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle, Skeleton,
} from '@mui/material';
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import AddIcon from "@mui/icons-material/Add";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { fetchTransactions } from '../../../redux/transactionsSlice';

const TransactionHistory = () => {
    const dispatch = useDispatch();
    const [isInitialLoad, setIsInitialLoad] = useState(true);
    const transactionsData = useSelector((state) => state.transactions.transactions);
    const { pagination, status } = useSelector((state) => state.transactions);
    const [searchQuery, setSearchQuery] = useState('');
    const [paginationModel, setPaginationModel] = useState({
        page: 0,
        pageSize: 10
    });
    const [totalCount, setTotalCount] = useState(0);
    const [open, setOpen] = useState(false);
    const [openPaymentSuccessDialog, setOpenPaymentSuccessDialog] = useState(false);

    useEffect(() => {
        console.log('Dispatching fetchTransactions with:', {
            page: paginationModel.page + 1,
            pageSize: paginationModel.pageSize,
            searchQuery: searchQuery
        });
        dispatch(fetchTransactions({
            page: paginationModel.page + 1,
            pageSize: paginationModel.pageSize,
            searchQuery: searchQuery
        })).then(() => {
            console.log('fetchTransactions completed');
            setIsInitialLoad(false);
        });
    }, [paginationModel, searchQuery, dispatch]);

    useEffect(() => {
        if (pagination) {
            setTotalCount(pagination.totalCount || 0);
        }
    }, [pagination]);

    useEffect(() => {
        console.log('Raw transactionsData:', transactionsData);
    }, [transactionsData]);

    const handleSearchChange = (event) => {
        setSearchQuery(event.target.value);
        setPaginationModel({ ...paginationModel, page: 0 });
    };

    const handlePaginationModelChange = (newModel) => {
        setPaginationModel(newModel);
    };

    const handleConfirm = () => {
        setOpen(false);
        setOpenPaymentSuccessDialog(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const renderSkeletons = () => (
        <Box sx={{padding:'0 32px 24px 32px'}}>
            {[...Array(5)].map((_, index) => (
                <Box key={index} sx={{ display: 'flex', alignItems: 'center', mx: 1, my: 2 }}>
                    <Skeleton variant="rectangular" width={60} height={40} sx={{ mr: 5, borderRadius:'4px' }} />
                    <Box sx={{ width: '100%' }}>
                        <Skeleton variant="text" width="100%" />
                        <Skeleton variant="text" width="100%" />
                    </Box>
                </Box>
            ))}
        </Box>
    );

    const rows = useMemo(() => {
        console.log('Transforming transactionsData:', transactionsData);
        return transactionsData?.map(transaction => ({
            id: transaction.id,
            expected_amount: transaction.expected_amount,
            paid_amount: transaction.paid_amount,
            expected_amount_expiry_date: transaction.expected_amount_expiry_date,
            paid_amount_date: transaction.paid_amount_date,
            is_paid: transaction.is_paid,
        })) || [];
    }, [transactionsData]);

    console.log('Transformed rows:', rows);

    const columns = [
        {
            field: 'id',
            headerName: 'ID',
            flex: 0.3,
            minWidth: 80,
            renderCell: (params) => (
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start', height: '100%', width: '100%' }}>
                    <Typography variant="body2">{params.value}</Typography>
                </Box>
            )
        },
        {
            field: 'expected_amount',
            headerName: 'Expected Amount',
            flex: 0.7,
            minWidth: 200,
            renderCell: (params) => (
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start', height: '100%', width: '100%' }}>
                    <Typography variant="body2">RM{params.value}</Typography>
                </Box>
            )
        },
        {
            field: 'paid_amount',
            headerName: 'Paid Amount',
            flex: 0.7,
            minWidth: 150,
            renderCell: (params) => (
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start', height: '100%', width: '100%'}}>
                    <Typography variant="body2">RM{params.value}</Typography>
                </Box>
            )
        },
        {
            field: 'expected_amount_expiry_date',
            headerName: 'Expected Amount Expiry Date',
            flex: 0.7,
            minWidth: 250,
            renderCell: (params) => (
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start', height: '100%', width: '100%'}}>
                    <Typography variant="body2">{new Date(params.value).toLocaleDateString()}</Typography>
                </Box>
            )
        },
        {
            field: 'paid_amount_date',
            headerName: 'Paid Amount Date',
            flex: 1,
            minWidth: 180,
            renderCell: (params) => (
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start', height: '100%', width: '100%'}}>
                    <Typography variant="body2">{new Date(params.value).toLocaleDateString()}</Typography>
                </Box>
            )
        },
        {
            field: 'is_paid',
            headerName: 'Is Paid',
            flex: 0.5,
            minWidth: 150,
            renderCell: (params) => (
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start', height: '100%', width: '100%'}}>
                    <Typography variant="body2">{params.value ? 'Yes' : 'No'}</Typography>
                </Box>
            )
        },
    ];

    return (
        <Box sx={{ display:'flex', padding:{xs:1,md:'32px'} }}>
            <Box flex={1}>
                <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-start', alignSelf: 'stretch', width: '100%'}}>
                    <Box>
                        <Typography variant='h1'>Transaction History</Typography>
                        <Typography variant='h2'>View your transaction history and details.</Typography>
                    </Box>
                    <Box sx={{ ml: 'auto', mr:'32px', display: 'flex', alignItems: 'center', gap:1 }}>
                        <Button variant='save' startIcon={<AddIcon />}>
                            Create payment
                        </Button>
                        <Button variant='cancel' onClick={() => setOpen(true)}>
                            Verify this Order
                        </Button>
                    </Box>
                </Box>

                <Divider/>

                <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
                    <Box/>
                    <Box display="flex" gap="12px">
                        <TextField
                            variant="outlined"
                            placeholder="Search by Description"
                            value={searchQuery}
                            onChange={handleSearchChange}
                            size="small"
                            sx={{
                                '& .MuiOutlinedInput-root': {
                                    height: '34px',
                                    '& input': {
                                        height: '34px',
                                        padding: '0 14px',
                                    },
                                    '& .MuiInputBase-input': {
                                        height: '34px',
                                        padding: '0 14px',
                                    }
                                }
                            }}
                        />
                    </Box>
                </Box>

                <Paper
                    sx={{
                        width: "100%",
                        borderRadius: "8px",
                        marginTop: '24px',
                        border: "1px solid var(--Gray-200, #EAECF0)",
                        background: "var(--White, #FFF)",
                        boxShadow: "0px 1px 3px 0px rgba(16, 24, 40, 0.10), 0px 1px 2px 0px rgba(16, 24, 40, 0.06)",
                    }}
                >
                    <Typography
                        sx={{
                            color: "var(--Gray-900, #101828)",
                            fontFamily: "Inter",
                            fontSize: "18px",
                            fontStyle: "normal",
                            fontWeight: 500,
                            lineHeight: "28px",
                            padding: "20px 24px",
                        }}
                    >
                        All Transactions
                    </Typography>

                    {(isInitialLoad || status === 'loading') ? (
                        renderSkeletons()
                    ) : (
                        <DataGrid
                            slots={{ toolbar: GridToolbar }}
                            rows={rows}
                            columns={columns}
                            paginationModel={paginationModel}
                            onPaginationModelChange={handlePaginationModelChange}
                            pageSizeOptions={[5, 10, 20]}
                            rowCount={totalCount}
                            paginationMode="server"
                            checkboxSelection
                            disableRowSelectionOnClick
                            rowHeight={70}
                            loading={status === 'loading'}
                            initialState={{
                                pagination: {
                                    paginationModel: {
                                        pageSize: 10,
                                    },
                                },
                            }}
                            sx={{
                                '& .MuiDataGrid-toolbarContainer .MuiButtonBase-root': {
                                    backgroundColor: '#737373',
                                    color: '#fff',
                                    height: '34px',
                                    my:1,
                                    minWidth: 'auto',
                                    '&:hover': {
                                        backgroundColor: '#404040',
                                    },
                                },
                            }}
                        />
                    )}
                </Paper>
            </Box>

            {/* Dialog for Verification */}
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>Verify Order</DialogTitle>
                <DialogContent>
                    <DialogContentText>Are you sure you want to verify this order?</DialogContentText>
                </DialogContent>
                <DialogActions sx={{ mb: 1 }}>
                    <Button variant='save' onClick={handleConfirm}>Confirm</Button>
                    <Button variant='cancel' onClick={handleClose}>Cancel</Button>
                </DialogActions>
            </Dialog>

            {/* Payment Success Dialog */}
            <Dialog
                open={openPaymentSuccessDialog}
                onClose={() => setOpenPaymentSuccessDialog(false)}
                maxWidth='xs'
                fullWidth
            >
                <DialogContent
                    sx={{
                        textAlign: 'center',
                        padding: { xs: '24px', sm: '32px' },
                    }}
                >
                    <CheckCircleIcon
                        color='success'
                        sx={{ fontSize: { xs: 40, sm: 60 } }}
                    />
                    <Typography
                        variant='h5'
                        fontWeight='bold'
                        gutterBottom
                        sx={{ fontSize: { xs: '1.25rem', sm: '1.5rem' } }}
                    >
                        Order Successfully Verified!
                    </Typography>
                    <Typography
                        variant='body2'
                        color='textSecondary'
                        gutterBottom
                    >
                        The order has been successfully verified. A confirmation email has been sent to admin@mycompany.com.
                    </Typography>
                    <Typography variant='body2' fontWeight='bold' gutterBottom>
                        Order Number
                    </Typography>
                    <Typography variant='h6' gutterBottom>
                        12345
                    </Typography>
                    <Button
                        variant='save'
                        onClick={() => setOpenPaymentSuccessDialog(false)}
                        sx={{
                            marginTop: '16px',
                            width: { xs: '100%', sm: 'auto' },
                        }}
                    >
                        Return to Orders
                    </Button>
                </DialogContent>
            </Dialog>
        </Box>
    );
};

export default TransactionHistory;