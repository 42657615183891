import React from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    Box,
    Grid
} from '@mui/material';

const ImageSelectionDialog = ({
                                  open,
                                  onClose,
                                  selectedImage,
                                  onImageSelect,
                                  images
                              }) => {
    return (
        <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
            <DialogTitle>Select Default Image</DialogTitle>
            <DialogContent>
                <Grid container spacing={2} justifyContent="center">
                    {images.map((image) => (
                        <Grid item key={image} xs={6} sm={4} md={4}>
                            <Box
                                component="img"
                                src={image}
                                alt={`Event option ${image}`}
                                sx={{
                                    width: '100%',
                                    height: '200px',
                                    objectFit: 'cover',
                                    cursor: 'pointer',
                                    borderRadius: '8px',
                                    border: selectedImage === image ? '3px solid #1976d2' : '1px solid #ccc',
                                    transition: 'transform 0.3s ease-in-out',
                                    '&:hover': {
                                        transform: 'scale(1.05)',
                                    },
                                }}
                                onClick={() => onImageSelect(image)}
                            />
                        </Grid>
                    ))}
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} variant="outlined">
                    Cancel
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default ImageSelectionDialog;