import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { getToken } from '../utils/authUtils'; // Import the getToken utility

// const API_BASE_URL = process.env.REACT_APP_API_BASE_URL_2;
const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
// const API_BASE_URL = 'http://localhost:3001/api'

// Helper function to generate headers with token
const getAuthHeaders = async () => {
    const token = await getToken(); // Fetch the token
    return {
        'Authorization': `Bearer ${token}`, // Add the Authorization header
        'Content-Type': 'application/json'
    };
};

// Thunk to fetch all events
// export const fetchEvents = createAsyncThunk(
//     'events/fetchEvents',
//     async ({ page, pageSize, searchQuery }, { rejectWithValue }) => {
//         try {
//             const headers = await getAuthHeaders();
//             const response = await axios.get(`${API_BASE_URL}/events`, {
//                 headers,
//                 params: {
//                     page: page + 1, // Adding 1 because backend might expect 1-indexed pages
//                     limit: pageSize,
//                     search: searchQuery,
//                 },
//             });
//
//             return {
//                 events: response.data.data.events,
//                 pagination: {
//                     totalCount: response.data.data.total,
//                     currentPage: response.data.data.current_page,
//                     totalPages: response.data.data.total_pages,
//                 },
//             };
//         } catch (error) {
//             return rejectWithValue(error.response?.data || { message: error.message });
//         }
//     }
// );

export const fetchEvents = createAsyncThunk(
    'events/fetchEvents',
    async ({ page, pageSize, searchQuery }, { rejectWithValue }) => {
        try {
            const headers = await getAuthHeaders();
            const response = await axios.get(`${API_BASE_URL}/events`, {
                headers,
                params: {
                    page,
                    limit: pageSize,
                    search: searchQuery,
                },
            });

            // Ensure we're returning an object with events array and pagination
            const events = Array.isArray(response.data.data) ? response.data.data : [];
            const pagination = response.data.pagination || {
                totalCount: events.length,
                currentPage: page,
                totalPages: 1,
            };
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response?.data || { message: error.message });
        }
    }
);

// Thunk to create a new event
export const createEvent = createAsyncThunk('events/create', async (eventData, { rejectWithValue }) => {
    try {
        if (!eventData.name || !eventData.event_start_date || !eventData.event_end_date) {
            throw new Error('Name, Start Event date and End Evetn Date are required fields');
        }

        const headers = await getAuthHeaders();
        const response = await axios.post(`${API_BASE_URL}/events`, eventData, { headers });

        if (!response.data || !response.data || !response.data) {
            throw new Error('Server response is missing event ID');
        }

        return response.data.data.event;
    } catch (error) {
        console.error('Error creating event:', error);
        return rejectWithValue(error.response?.data || { message: error.message });
    }
});

// Thunk to fetch a specific event by ID
export const fetchEventById = createAsyncThunk('events/fetchById', async (id, { rejectWithValue }) => {
    try {
        const headers = await getAuthHeaders(); // Use async headers function
        const response = await axios.get(`${API_BASE_URL}/events?id=${id}`, { headers });
        return response.data;
    } catch (error) {
        return rejectWithValue(error.response?.data || { message: error.message });
    }
});

// Thunk to update an event by ID
export const updateEvent = createAsyncThunk('events/update',
    async (editedEvent, { rejectWithValue }) => {
        try {
            const headers = await getAuthHeaders();
            const response = await axios.patch(`${API_BASE_URL}/events?id=${editedEvent.id}`, editedEvent, { headers });
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response?.data || { message: error.message });
        }
    });

// Thunk to update event status
export const updateEventStatus = createAsyncThunk('events/updateStatus', async ({ id, entityId, statusData }, { rejectWithValue }) => {
    try {
        const headers = await getAuthHeaders(); // Use async headers function
        const response = await axios.patch(`${API_BASE_URL}/events/${id}/status/${entityId}`, statusData, { headers });
        return response.data;
    } catch (error) {
        return rejectWithValue(error.response?.data || { message: error.message });
    }
});

// Thunk to delete an event by ID
export const deleteEvent = createAsyncThunk('events/delete', async (id, { rejectWithValue }) => {
    try {
        const headers = await getAuthHeaders(); // Use async headers function
        await axios.delete(`${API_BASE_URL}/events?id=${id}`, { headers });
        return id;
    } catch (error) {
        return rejectWithValue(error.response?.data || { message: error.message });
    }
});

// Slice for Events Operations
const eventsSlice = createSlice({
    name: 'events',
    initialState: {
        events: [],
        status: 'idle',
        error: null,
        newlyCreatedEvent: null,
        selectedEvent: null,
        pagination: null
    },
    reducers: {
        clearNewlyCreatedEvent: (state) => {
            state.newlyCreatedEvent = null;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchEvents.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchEvents.fulfilled, (state, action) => {
                console.log('Fulfilling fetchEvents with payload:', action.payload);
                console.log('Events:', action.payload.data.events);
                console.log('Pagination:', action.payload.data.pagination);
                state.status = 'succeeded';
                state.events = action.payload.data.events;
                state.pagination = action.payload.data.pagination;
            })
            .addCase(fetchEvents.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload?.message || 'Failed to fetch events';
            })

            .addCase(createEvent.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(createEvent.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.events.unshift(action.payload); // Add new event to the beginning of the array
                state.newlyCreatedEvent = action.payload;
            })
            .addCase(createEvent.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload.message || 'Unknown error occurred';
            })

            .addCase(fetchEventById.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchEventById.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.selectedEvent = action.payload;
            })
            .addCase(fetchEventById.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload?.message || 'Failed to fetch event by ID';
            })

            .addCase(updateEvent.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(updateEvent.fulfilled, (state, action) => {
                state.status = 'succeeded';
                const index = state.events.findIndex(event => event.id === action.payload.id);
                if (index !== -1) state.events[index] = action.payload;
                if (state.selectedEvent && state.selectedEvent.id === action.payload.id) {
                    state.selectedEvent = action.payload;
                }
            })
            .addCase(updateEvent.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload?.message || 'Failed to update event';
            })

            .addCase(updateEventStatus.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(updateEventStatus.fulfilled, (state, action) => {
                state.status = 'succeeded';
                const index = state.events.findIndex(event => event.id === action.payload.id);
                if (index !== -1) state.events[index] = action.payload;
                if (state.selectedEvent && state.selectedEvent.id === action.payload.id) {
                    state.selectedEvent = action.payload;
                }
            })
            .addCase(updateEventStatus.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload?.message || 'Failed to update event status';
            })

            .addCase(deleteEvent.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(deleteEvent.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.events = state.events.filter(event => event.id !== action.payload);
                if (state.selectedEvent && state.selectedEvent.id === action.payload) {
                    state.selectedEvent = null;
                }
            })
            .addCase(deleteEvent.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload?.message || 'Failed to delete event';
            });
    },
});

export const { clearNewlyCreatedEvent } = eventsSlice.actions;
export default eventsSlice.reducer;
