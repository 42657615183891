import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    Box,
    Typography,
    TextField,
    Select,
    MenuItem,
    FormControl,
    InputLabel,
    Grid,
    Paper,
    Button,
    Popover, Checkbox, FormControlLabel,
    Link,
    CircularProgress, Alert,
} from '@mui/material';
import { QRCodeSVG } from 'qrcode.react';
import { styled } from '@mui/system';
import { ChromePicker } from 'react-color';
import { CompanyLogo } from "../../../header/HeaderSvgImages";
import { fetchThemeComponents, updateThemeComponent } from '../../../../redux/themeComponentsSlice';
import keycloak from "../../../../keycloak";
import { PutObjectCommand, S3Client } from "@aws-sdk/client-s3";
import { v4 as uuidv4 } from 'uuid';
import {fetchOrganizationPublicDetails} from "../../../../redux/organizationPublicDetailsSlice";

const s3Client = new S3Client({
    region: "ap-southeast-1",
    credentials: {
        accessKeyId: "AKIA5FTZAZQQCCRKEWU7",
        secretAccessKey: "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",
    },
});

const handleGenerateQR = () => {
    // Generate a random URL for demonstration
    setQrValue(`https://eventlah.com/${Math.random().toString(36).substr(2, 8)}`);
};


const StyledPaper = styled(Paper)(({ theme }) => ({
    padding: theme.spacing(2),
    margin: theme.spacing(2),
}));

const ColorBox = styled(Box)(({ bgcolor }) => ({
    width: '24px',
    height: '24px',
    backgroundColor: bgcolor,
    cursor: 'pointer',
    border: '1px solid #000',
    borderRadius: '50%',
    marginRight: '8px',
}));

const TicketTab = () => {
    const dispatch = useDispatch();
    const { themeComponents, status, error } = useSelector(state => state.themeComponents);
    const [isEditing, setIsEditing] = useState(false);
    const [uploading, setUploading] = useState(false);
    const [qrValue, setQrValue] = useState('https://eventlah.com');

    const [formData, setFormData] = useState({
        logo: <CompanyLogo/>,
        name: '',
        mainText: '',
        description: '',
        content: '',
        button1: '',
        footerText: '',
        button2: '',
        font: '',
        companyName: '',
        companyAddress: '',
        businessWebsite: '',
        showCompanyName: false,
        showCompanyAddress: false,
        showBusinessWebsite: false,
        showQRCodeScanner: false,
        colors: {
            body: {
                text: '#000000',
                buttonsAndLinks: '#FEE000',
                buttonLabelsAndOutlines: '#232323',
            },
            background: {
                background1: '#f5f5f5',
                background2: '#FEE000',
            },
            footer: {
                textAndIcons: '#232323',
                background: '#FFF',
            },
        },
    });

    const [colorPickerAnchor, setColorPickerAnchor] = useState(null);
    const [activeColorField, setActiveColorField] = useState(null);

    const organizationPublicDetails = useSelector((state) => state.organizationPublicDetails.details);

    useEffect(() => {
        if (organizationPublicDetails && organizationPublicDetails.length > 0) {
            setFormData(prevData => ({
                ...prevData,
                companyName: organizationPublicDetails[0]?.data?.business_name || '',
                companyAddress: `${organizationPublicDetails[0]?.data?.customer_support_address_line_1 || ''}, ${organizationPublicDetails[0]?.data?.customer_support_address_line_2 || ''}, ${organizationPublicDetails[0]?.data?.postal_code || ''}, ${organizationPublicDetails[0]?.data?.city || ''}, ${organizationPublicDetails[0]?.data?.state || ''}`.replace(/^[,\s]+|[,\s]+$/g, '').replace(/,\s*,/g, ','),
                businessWebsite: organizationPublicDetails[0]?.data?.business_website || '',
            }));
        }
    }, [organizationPublicDetails]);

    useEffect(() => {
        if (status === 'idle' && !isEditing) {
            const fetchData = async () => {
                try {
                    const token = await keycloak.updateToken(30);
                    const organization = keycloak.tokenParsed?.organization;
                    if (organization) {
                        dispatch(fetchOrganizationPublicDetails(organization));
                        dispatch(fetchThemeComponents({ page: 1, limit: 10, organization_id: organization }));
                    } else {
                        console.error('Organization not found in the token');
                    }
                } catch (error) {
                    console.error('Failed to update token or fetch theme components:', error);
                }
            };
            fetchData();
        }
    }, [dispatch, status, isEditing]);

    useEffect(() => {
        if (themeComponents.length > 0 && !isEditing) {
            const ticketComponents = themeComponents.filter(component => component.name === 'ticket');
            if (ticketComponents.length > 0) {
                const ticketComponent = ticketComponents[0];
                setFormData(prevData => ({
                    ...prevData,
                    mainText: ticketComponent.main_text || 'Ticket',
                    description: ticketComponent.description || 'Descriptions',
                    font: ticketComponent.typography || 'Helvetica',
                    showCompanyName: ticketComponent.show_company_name || false,
                    showCompanyAddress: ticketComponent.show_company_address || false,
                    showBusinessWebsite: ticketComponent.show_business_website || false,
                    showQRCodeScanner: ticketComponent.show_qr_code_scanner || false,
                    colors: {
                        body: {
                            text: ticketComponent.body_text_color || '#000000',
                            buttonsAndLinks: ticketComponent.button_color || '#FEE000',
                            buttonLabelsAndOutlines: ticketComponent.button_label_color || '#232323',
                        },
                        background: {
                            background1: ticketComponent.background_color_1 || '#f5f5f5',
                            background2: ticketComponent.background_color_2 || '#FEE000',
                        },
                        footer: {
                            textAndIcons: ticketComponent.footer_text_color || '#232323',
                            background: ticketComponent.footer_background_color || '#FFF',
                        },
                    },
                    content: ticketComponent.other_text_1 || 'Top-rated hosts of the month',
                    button1: ticketComponent.other_text_2 || 'Explore your host details above',
                    footerText: ticketComponent.other_text_3 || 'Your adventure begins now!',
                    button2: ticketComponent.other_text_4 || 'Discover more exclusive deals',
                    logo: ticketComponent.logo || <CompanyLogo/>,
                }));
            }
        }
    }, [themeComponents, isEditing]);

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setIsEditing(true);
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const handleSave = async () => {
        const ticketComponent = themeComponents.find(component => component.name === 'ticket');
        if (ticketComponent) {
            const serializableData = {
                name: 'ticket',
                main_text: formData.mainText,
                description: formData.description,
                typography: formData.font,
                show_company_name: formData.showCompanyName,
                show_company_address: formData.showCompanyAddress,
                show_business_website: formData.showBusinessWebsite,
                show_qr_code_scanner: formData.showQRCodeScanner,
                body_text_color: formData.colors.body.text,
                button_color: formData.colors.body.buttonsAndLinks,
                button_label_color: formData.colors.body.buttonLabelsAndOutlines,
                background_color_1: formData.colors.background.background1,
                background_color_2: formData.colors.background.background2,
                footer_text_color: formData.colors.footer.textAndIcons,
                footer_background_color: formData.colors.footer.background,
                other_text_1: formData.content,
                other_text_2: formData.button1,
                other_text_3: formData.footerText,
                other_text_4: formData.button2,
                logo_url: formData.logo,
            };

            try {
                const result = await dispatch(updateThemeComponent({
                    id: ticketComponent.id,
                    themeComponentData: serializableData
                })).unwrap();

                console.log('Update result:', result);

                setFormData(prevData => ({
                    ...prevData,
                    ...serializableData
                }));

                const organization = keycloak.tokenParsed?.organization;
                if (organization) {
                    await dispatch(fetchThemeComponents({ page: 1, limit: 10, organization_id: organization }));
                }

                setIsEditing(false);
            } catch (error) {
                console.error('Update failed:', error);
            }
        } else {
            console.error('Ticket component not found');
        }
    };

    const handleCheckboxChange = (event) => {
        const { name, checked } = event.target;
        setIsEditing(true);
        setFormData((prevData) => ({
            ...prevData,
            [name]: checked,
        }));
    };

    const handleColorChange = (color) => {
        if (!activeColorField) return;

        setIsEditing(true);
        setFormData((prevData) => ({
            ...prevData,
            colors: {
                ...prevData.colors,
                [activeColorField.group]: {
                    ...prevData.colors[activeColorField.group],
                    [activeColorField.field]: color.hex,
                },
            },
        }));
    };

    const handleColorBoxClick = (event, group, field) => {
        setColorPickerAnchor(event.currentTarget);
        setActiveColorField({ group, field });
    };

    const handleLogoUpload = async (event) => {
        const file = event.target.files[0];
        if (file) {
            setUploading(true);
            try {
                const timestamp = Date.now();
                const uniqueId = uuidv4().slice(0, 6);
                const fileNameParts = file.name.split('.');
                const fileExtension = fileNameParts.pop();
                const fileName = fileNameParts.join('.');
                const uniqueFileName = `${fileName}_${timestamp}_${uniqueId}.${fileExtension}`;
                const organizationId = keycloak.tokenParsed?.organization || '1';
                const key = `${organizationId}/${uniqueFileName}`;

                const params = {
                    Bucket: "ev-merchants",
                    Key: key,
                    Body: file,
                    ContentType: file.type,
                };

                const response = await s3Client.send(new PutObjectCommand(params));
                console.info('S3 upload response:', response);

                const fileUrl = `https://ev-merchants.s3.ap-southeast-1.amazonaws.com/${encodeURIComponent(key)}`;

                const ticketComponent = themeComponents.find(component => component.name === 'ticket');
                const result = await dispatch(updateThemeComponent({
                    id: ticketComponent.id,
                    themeComponentData: {
                        logo: fileUrl
                    }
                })).unwrap();

                setFormData((prevData) => ({
                    ...prevData,
                    logo: fileUrl,
                }));
                setIsEditing(true);
            } catch (err) {
                console.error("Error uploading file:", err);
                // Handle error (e.g., show error message to user)
            } finally {
                setUploading(false);
            }
        }
    };

    const renderColorOption = (group, field, label, allowNone = false) => {
        const color = formData.colors[group][field];
        return (
            <Box display="flex" alignItems="center" mb={1}>
                <ColorBox
                    bgcolor={color || 'transparent'}
                    onClick={(e) => handleColorBoxClick(e, group, field)}
                />
                <Typography variant="body2">
                    {label}
                    {color ? ` ${color}` : ' None'}
                </Typography>
            </Box>
        );
    };

    const fontOptions = [
        'Arial', 'Helvetica', 'Verdana', 'Trebuchet MS', 'Gill Sans',
        'Noto Sans', 'Avantgarde', 'Optima', 'Arial Narrow', 'sans-serif',
        'Times', 'Times New Roman', 'Didot', 'Georgia', 'Palatino',
        'Bookman', 'Comic Sans MS', 'Trebuchet MS', 'Arial Black', 'Impact'
    ];

    if (status === 'failed') {
        return <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
            <Typography color="error">Error: {error}</Typography>
        </Box>;
    }

    return (
        <Box sx={{ display: 'flex', minHeight: '100vh', borderRadius:'12px' }}>
            <StyledPaper elevation={3} sx={{flex: 0.8, overflowY: 'auto'}}>
                <Typography sx={{fontSize: '16px', fontWeight: '700'}} gutterBottom>
                    Logo
                </Typography>
                <Typography variant="caption" gutterBottom>
                    Recommended image size: 250px x 250px
                </Typography> <br />
                <label htmlFor="raised-button-file">
                    <input
                        accept="image/*"
                        style={{display: 'none'}}
                        id="raised-button-file"
                        type="file"
                        onChange={handleLogoUpload}
                    />
                    <Button variant="save" component="span" disabled={uploading}>
                        {uploading ? <CircularProgress size={24} /> : 'Upload Logo'}
                    </Button>
                </label>
                {/*<label htmlFor="raised-button-file">*/}
                {/*    <Button variant="save" component="span" disabled={uploading}>*/}
                {/*        {uploading ? <CircularProgress size={24}/> : 'Upload Logo'}*/}
                {/*    </Button>*/}
                {/*</label>*/}

                <Typography variant="h6" gutterBottom sx={{fontSize: '16px', fontWeight: '700', mt: 4}}>
                    Main Text
                </Typography>
                <TextField
                    fullWidth
                    variant="outlined"
                    name="mainText"
                    value={formData.mainText}
                    onChange={handleInputChange}
                    sx={{mb: 2}}
                />

                <Typography gutterBottom sx={{fontSize: '16px', fontWeight: '700', mt: 2}}>
                    Description
                </Typography>
                <TextField
                    fullWidth
                    variant="outlined"
                    name="description"
                    value={formData.description}
                    onChange={handleInputChange}
                    sx={{mb: 2}}
                />

                <Typography gutterBottom sx={{fontSize: '16px', fontWeight: '700', mt: 2}}>
                    Typography
                </Typography>
                <FormControl fullWidth variant="outlined" sx={{mb: 2}}>
                    <InputLabel>Font</InputLabel>
                    <Select
                        value={formData.font}
                        onChange={handleInputChange}
                        label="Font"
                        name="font"
                    >
                        {fontOptions.map((font) => (
                            <MenuItem key={font} value={font}>{font}</MenuItem>
                        ))}
                    </Select>
                </FormControl>

                <Typography sx={{fontSize: '16px', fontWeight: '700', mt: 2}} gutterBottom>
                    Company Information.
                    <Alert severity="info" sx={{mt: 1, mb: 2}}>
                        If you want to change your business details, <Link
                        href="/settings/profile-settings#public-details"
                        underline="always"
                        sx={{color: 'black', '&:hover': {color: 'black'}, fontWeight: 'bold'}}
                    >
                        click here
                    </Link></Alert>
                </Typography>
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'flex-start',
                }}>
                    <FormControlLabel
                        control={<Checkbox
                            checked={formData.showCompanyName}
                            onChange={handleCheckboxChange}
                            name="showCompanyName"
                        />}
                        label="Show Company Name"
                        sx={{marginLeft: 0}}
                    />
                    <FormControlLabel
                        control={<Checkbox
                            checked={formData.showCompanyAddress}
                            onChange={handleCheckboxChange}
                            name="showCompanyAddress"
                        />}
                        label="Show Company Address"
                        sx={{marginLeft: 0}}
                    />
                    <FormControlLabel
                        control={<Checkbox
                            checked={formData.showBusinessWebsite}
                            onChange={handleCheckboxChange}
                            name="showBusinessWebsite"
                        />}
                        label="Show Business Website"
                        sx={{marginLeft: 0}}
                    />
                </Box>

                <Typography sx={{fontSize: '16px', fontWeight: '700', mt: 2}} gutterBottom>
                    Colors
                </Typography>
                <Box mb={2}>
                    <Typography variant="subtitle1">Body</Typography>
                    {renderColorOption('body', 'text', 'Text')}
                    {renderColorOption('body', 'buttonsAndLinks', 'Buttons and links')}
                    {renderColorOption('body', 'buttonLabelsAndOutlines', 'Button labels and outlines')}
                </Box>
                <Box mb={2}>
                    <Typography variant="subtitle1">Background</Typography>
                    {renderColorOption('background', 'background1', 'Background 1')}
                    {renderColorOption('background', 'background2', 'Background 2')}
                </Box>
                <Box mb={2}>
                    <Typography variant="subtitle1">Footer</Typography>
                    {renderColorOption('footer', 'textAndIcons', 'Text and icons', true)}
                    {renderColorOption('footer', 'background', 'Background', true)}
                </Box>

                <Typography sx={{fontSize: '16px', fontWeight: '700', mt: 2}} gutterBottom>
                    Other Texts
                </Typography>
                {[
                    {name: 'footerText', label: 'Footer Text'},
                    {name: 'button2', label: 'Button 2'}
                ].map(({name, label}) => (
                    <TextField
                        key={name}
                        fullWidth
                        variant="outlined"
                        name={name}
                        label={label}
                        value={formData[name]}
                        onChange={handleInputChange}
                        sx={{mb: 2}}
                    />
                ))}

                <Button variant='save' onClick={handleSave} disabled={!isEditing}>
                    Save Changes
                </Button>
            </StyledPaper>

            {/* Preview */}
            <StyledPaper elevation={3} sx={{flex: 1, borderRadius: '12px'}}>
                <Box sx={{
                    bgcolor: formData.colors.background.background1,
                    p: 2,
                    mb: 6,
                    mt: 3,
                    textAlign: 'center',
                    borderRadius: '12px',
                    fontFamily: formData.font }}>
                    <Box sx={{mb:3}}>
                        {typeof formData.logo === 'string' ? (
                            <img src={formData.logo} alt="Company Logo" style={{ width: '250px', maxWidth: '100%', height: 'auto' }} />
                        ) : (
                            formData.logo
                        )}
                    </Box>
                    {formData.showCompanyName && (
                        <Typography variant="h6" sx={{ color: formData.colors.body.text, fontFamily: 'inherit' }}>
                            {formData.companyName}
                        </Typography>
                    )}
                    {formData.showCompanyAddress && (
                        <Typography variant="body2" sx={{ color: formData.colors.body.text, fontFamily: 'inherit' }}>
                            {formData.companyAddress}
                        </Typography>
                    )}
                    {formData.showBusinessWebsite && (
                        <Link
                            href={formData.businessWebsite}
                            target="_blank"
                            rel="noopener noreferrer"
                            sx={{
                                color: formData.colors.body.buttonLabelsAndOutlines,
                                fontFamily: 'inherit',
                                textDecoration: 'none',
                                '&:hover': {
                                    textDecoration: 'underline'
                                }
                            }}
                        >
                            {formData.businessWebsite}
                        </Link>
                    )}
                    <Box sx={{backgroundColor: formData.colors.body.text, height:'1px', mx:'auto', mb:3, mt: 2}}/>
                    <Typography variant="h4" sx={{ color: formData.colors.body.text, mb: 1, fontFamily: 'inherit' }}>
                        {formData.mainText}
                    </Typography>
                    <Typography variant="body1" sx={{ color: formData.colors.body.text, fontFamily: 'inherit', pb:2 }}>
                        {formData.description}
                    </Typography>
                </Box>

                <Box sx={{ bgcolor: formData.colors.footer.background || 'transparent', color: formData.colors.footer.textAndIcons || formData.colors.body.text, p: 2, mt: 2, textAlign: 'center', fontFamily: formData.font }}>
                    <Typography variant="h6" sx={{ fontFamily: 'inherit' }}>
                       Reservation details: <br />
                        1 x Adult
                    </Typography>
                </Box>

                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Paper
                            elevation={2}
                            sx={{
                                p: 2,
                                borderRadius: '12px',
                                height: 250,
                                width: 250,
                                mx: 'auto',
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                justifyContent: 'center',
                                bgcolor: formData.colors.background.background2,
                                fontFamily: formData.font
                            }}
                        >
                            <Typography
                                variant="h6"
                                sx={{
                                    color: formData.colors.body.text,
                                    fontFamily: 'inherit',
                                    mb: 2
                                }}
                            >
                                1 Adult
                            </Typography>
                            <QRCodeSVG
                                value={qrValue}
                                size={150}
                                bgColor={formData.colors.background.background2}
                                fgColor={formData.colors.body.text}
                            />
                        </Paper>
                    </Grid>
                </Grid>

                <Box sx={{ bgcolor: formData.colors.footer.background || 'transparent', color: formData.colors.footer.textAndIcons || formData.colors.body.text, p: 2, mt: 2, textAlign: 'center', fontFamily: formData.font }}>
                    <Typography variant="h6" sx={{ fontFamily: 'inherit' }}>
                        {formData.footerText}
                    </Typography>
                    <Button
                        variant="contained"
                        sx={{
                            mt: 1,
                            bgcolor: formData.colors.body.buttonsAndLinks,
                            color: formData.colors.body.buttonLabelsAndOutlines,
                            fontFamily: 'inherit',
                            '&:hover': {
                                bgcolor: formData.colors.body.buttonsAndLinks,
                                opacity: 0.9,
                            }
                        }}
                    >
                        {formData.button2}
                    </Button>
                </Box>
            </StyledPaper>

            <Popover
                open={Boolean(colorPickerAnchor)}
                anchorEl={colorPickerAnchor}
                onClose={() => {
                    setColorPickerAnchor(null);
                    setActiveColorField(null);
                }}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
            >
                <ChromePicker
                    color={activeColorField ? formData.colors[activeColorField.group][activeColorField.field] : '#fff'}
                    onChange={handleColorChange}
                />
            </Popover>
        </Box>
    );
};

export default TicketTab;