import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Button, Box, Tabs, Tab, Divider, Typography } from '@mui/material';
import CustomizeProduct from "./CustomizeProduct";
import ProductImage from "./ProductImage";
import ProductPackage from "../../../../shared/ProductPackage";
import ProductVariant from "./ProductVariant";
import EventList from "./EventList";

const AddProduct = () => {
    const [value, setValue] = useState(0);
    const location = useLocation();
    const product = location.state?.product || {}; // Retrieve product data from the location state
    const [productId, setProductId] = useState(null);

    useEffect(() => {
        if (product && product.id) {
            setProductId(product.id); // Set product ID from the passed product data
        }
    }, [product]);

    const handleTabChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <Box display='flex'>
            <Box flex={1}>
                <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-start', alignSelf: 'stretch', width: '100%'}}>
                    <Box>
                        <Typography variant='h1'>
                            {productId ? 'Edit Product' : 'Add New Product'}
                        </Typography>
                        <Typography variant='h2'>
                            {productId ? 'Edit your product details.' : 'Add a new product to your catalog.'}
                        </Typography>
                    </Box>
                    <Box sx={{ ml: 'auto', display: 'flex', gap: '12px' }}>
                        <Button variant="cancel" onClick={ ()=>{} }>Cancel</Button>
                        <Button variant="save" onClick={ ()=>{} }>Save as Draft</Button>
                    </Box>
                </Box>

                <Box height='38px' paddingTop='32px'>
                    <Tabs value={value} onChange={handleTabChange} aria-label="spaces tabs">
                        <Tab label="customize product" />
                        <Tab label="product image" />
                        {/*<Tab label="product package" />*/}
                        <Tab label="product variant" />
                    </Tabs>
                </Box>

                <Divider sx={{margin: '41px 0 32px 0'}}/>

                <Box>
                    { value === 0 && <CustomizeProduct product={product} /> }
                    { value === 1 && <ProductImage productId={product.id} product={product} /> }
                    {/*{ value === 2 && <ProductPackage product={product} /> }*/}
                    { value === 2 && <ProductVariant productId={product.id} /> }
                </Box>
            </Box>
        </Box>
    );
};

export default AddProduct;