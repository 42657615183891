import React from 'react';
import { useSelector } from 'react-redux';
import {
    Drawer,
    Box,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Toolbar,
    Collapse,
    useMediaQuery,
    useTheme,
    Typography
} from '@mui/material';
import { Link } from 'react-router-dom';
import {
    Dashboard as DashboardIcon,
    People as PeopleIcon,
    Receipt as ReceiptIcon,
    ExitToApp as ExitToAppIcon,
    ExpandLess,
    ExpandMore,
    AdminPanelSettings as AdminIcon,
    Storefront as StorefrontIcon,
    Assignment as AssignmentIcon,
    EventNote as EventNoteIcon,
    CalendarToday as CalendarTodayIcon,
    AccountBalanceWallet as AccountBalanceWalletIcon,
} from '@mui/icons-material';

import keycloak from "../keycloak";
import { styled } from "@mui/system";

const CustomListItemText = styled(ListItemText)(({ theme }) => ({
    '& .MuiTypography-root': {
        color: 'var(--gray-400, #888)',
        fontSize: '13px',
        fontStyle: 'normal',
        fontWeight: '400',
        lineHeight: '12px',
    },
}));

const drawerWidth = 311;

const Sidebar = ({ isOpen, toggleSidebar }) => {
    const [openAdmin, setOpenAdmin] = React.useState(false);

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    // Get the store name from the Redux store
    const storeName = useSelector(state =>
        state.organizationProfiles?.profiles[0]?.data?.name || 'Merchant Store Name'
    );
    const handleLogout = () => {
        keycloak.logout();
    };

    const handleAdminClick = () => {
        setOpenAdmin(!openAdmin);
    };

    const isAdmin = keycloak.hasRealmRole('eventlah-admin');

    return (
        <Drawer
            variant={isMobile ? "temporary" : "persistent"}
            anchor="left"
            open={isOpen}
            onClose={toggleSidebar}
            sx={{
                width: drawerWidth,
                flexShrink: 0,
                [`& .MuiDrawer-paper`]: { width: drawerWidth, boxSizing: 'border-box' },
            }}
        >
            <Toolbar />
            <Box sx={{ overflow: 'auto' }}>
                {/* Merchant view */}
                {!isAdmin && (
                    <Box>
                        <ListItem>
                            <Box sx={{mt:1}}>
                                <Typography sx={{fontWeight:'700', fontSize:'20px'}}>{storeName}</Typography>
                                <Typography sx={{fontWeight:'400', fontSize:'15px'}}>Merchant Account Status</Typography>
                            </Box>
                        </ListItem>

                        <List>
                            <ListItem button component={Link} to="/">
                                <ListItemIcon><DashboardIcon /></ListItemIcon>
                                <ListItemText primary="Dashboard" />
                            </ListItem>
                            {[
                                { text: 'Products', icon: <StorefrontIcon />, link: '/settings/product-catalog' },
                                { text: 'Orders', icon: <AssignmentIcon />, link: '/settings/orders' },
                                { text: 'Ticketing', icon: <EventNoteIcon />, link: '/ticketing-overview' },
                                { text: 'Appointments', icon: <CalendarTodayIcon />, link: '/settings/appointments' },
                                { text: 'Transactions', icon: <AccountBalanceWalletIcon />, link: '/settings/transaction-history' },
                                { text: 'Customers', icon: <PeopleIcon />, link: '/settings/customer-records' },
                                { text: 'Agents', icon: <PeopleIcon />, link: '/agents-overview' },
                                { text: 'Billings', icon: <ReceiptIcon />, link: '/settings/account-status#billing' },
                            ].map((item) => (
                                <ListItem button component={Link} to={item.link} key={item.text}>
                                    <ListItemIcon>{item.icon}</ListItemIcon>
                                    <ListItemText primary={item.text} />
                                </ListItem>
                            ))}
                        </List>
                    </Box>
                )}

                {/* Admin view */}
                {isAdmin && (
                    <Box>
                        <ListItem>
                            <Box sx={{mt:1}}>
                                <Typography sx={{fontWeight:'700', fontSize:'20px'}}>Admin Dashboard</Typography>
                                <Typography sx={{fontWeight:'400', fontSize:'15px'}}>Admin Account</Typography>
                            </Box>
                        </ListItem>

                        <ListItem button component={Link} to="/">
                            <ListItemIcon><DashboardIcon /></ListItemIcon>
                            <ListItemText primary="Dashboard" />
                        </ListItem>

                        <ListItem button onClick={handleAdminClick}>
                            <ListItemIcon><AdminIcon /></ListItemIcon>
                            <ListItemText primary="Merchant Overview" />
                            {openAdmin ? <ExpandLess /> : <ExpandMore />}
                        </ListItem>

                        <Collapse in={openAdmin} timeout="auto" unmountOnExit>
                            <List component="div" disablePadding>
                                {/* The check for 'eventlah-admin' role is redundant here since the parent already checked it */}
                                <ListItem button component={Link} to="/admin/admin-dashboard" sx={{ pl: 9 }}>
                                    <CustomListItemText primary="Admin Dashboard" />
                                </ListItem>
                                <ListItem button component={Link} to="/admin/onboarding" sx={{ pl: 9 }}>
                                    <CustomListItemText primary="Onboarding" />
                                </ListItem>
                                <ListItem button component={Link} to="/admin/agreement" sx={{ pl: 9 }}>
                                    <CustomListItemText primary="Agreement Management" />
                                </ListItem>
                                <ListItem button component={Link} to="/admin/email-management" sx={{ pl: 9 }}>
                                    <CustomListItemText primary="ConfirmationEmail Management" />
                                </ListItem>
                                <ListItem button component={Link} to="/admin/subscriptions" sx={{ pl: 9 }}>
                                    <CustomListItemText primary="Admin Subscriptions" />
                                </ListItem>
                                <ListItem button component={Link} to="/admin/subscription-features" sx={{ pl: 9 }}>
                                    <CustomListItemText primary="Admin Subscriptions Features" />
                                </ListItem>
                            </List>
                        </Collapse>
                    </Box>
                )}

                <ListItem button onClick={handleLogout}>
                    <ListItemIcon><ExitToAppIcon /></ListItemIcon>
                    <ListItemText primary="Logout" />
                </ListItem>
            </Box>
        </Drawer>
    );
};

export default Sidebar;