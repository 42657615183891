import React, {useEffect, useState} from 'react';
import { Box, Tabs, Tab, Divider, Typography} from '@mui/material';
import ConfirmationEmail from "./BrandingContents/ConfirmationEmail";
import TicketTab from "./BrandingContents/TicketTab";
import InvoiceTab from "./BrandingContents/InvoiceTab";
import ReceiptTab from "./BrandingContents/ReceiptTab";
import CheckoutTab from "./BrandingContents/CheckoutTab";

const Branding = () => {
    const [value, setValue] = useState(0);
    const handleTabChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <Box display='flex'>
            <Box flex={1}>
                <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-start', alignSelf: 'stretch', width: '100%'}}>
                    <Box>
                        <Typography variant='h1'>
                            Branding
                        </Typography >
                        <Typography variant='h2'>
                            Personalize Your Presence with Custom Templates
                        </Typography>
                    </Box>
                    <Box sx={{ ml: 'auto', display: 'flex', gap: '12px' }}>
                        {/*<Button variant="save" color="primary" startIcon={<AddIcon />} sx={{height:'40px'}} onClick={handleClick}>*/}
                        {/*    asd*/}
                        {/*</Button>*/}
                    </Box>
                </Box>

                <Box height='38px' paddingTop='32px'>
                    <Tabs value={value} onChange={handleTabChange} aria-label="service tabs">
                        <Tab label="Ticket" />
                        <Tab label="Email" />
                        <Tab label="Invoice" />
                        <Tab label="Receipt" />
                        <Tab label="Checkout" />
                    </Tabs>
                </Box>

                <Divider sx={{margin: '41px 0 32px 0'}}/>

                <Box>
                    { value === 0 && <TicketTab/> }
                    { value === 1 && <ConfirmationEmail/> }
                    { value === 2 &&  <InvoiceTab/> }
                    { value === 3 &&  <ReceiptTab/> }
                    { value === 4 &&  <CheckoutTab/> }
                </Box>
            </Box>
        </Box>

    );
};

export default Branding;
