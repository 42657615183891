import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    fetchProductVariants,
    createProductVariant,
    updateProductVariant,
    deleteProductVariant
} from '../../../../../redux/productSlice';
import {
    Box,
    Button,
    TextField,
    Typography,
    Paper,
    IconButton,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    InputAdornment,
    Grid,
    ButtonGroup,
    Alert,
    Snackbar,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';

const VariantTable = ({ variant, updateVariantValue, ids, removeOptionValue }) => {
    return (
        <TableContainer component={Paper} sx={{ marginTop: 2, marginBottom: 2 }}>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>Variant</TableCell>
                        <TableCell>Price</TableCell>
                        <TableCell>Action</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {variant.optionValues.map((value, index) => (
                        <TableRow key={index}>
                            <TableCell>
                                <TextField
                                    fullWidth
                                    value={value}
                                    onChange={(e) => updateVariantValue(variant.id, 'optionValues', index, e.target.value)}
                                    placeholder={`Value ${index + 1}`}
                                />
                            </TableCell>
                            <TableCell>
                                <TextField
                                    size="small"
                                    placeholder="20.00"
                                    type="number"
                                    value={variant.prices[index] || ""}
                                    onChange={(e) => updateVariantValue(variant.id, 'prices', index, e.target.value)}
                                    inputProps={{
                                        step: "0.01",
                                        min: "0"
                                    }}
                                    InputProps={{
                                        startAdornment: <InputAdornment position="start">RM</InputAdornment>
                                    }}
                                />
                            </TableCell>
                            <TableCell>
                                <IconButton onClick={() => removeOptionValue(variant.id, index)}>
                                    <DeleteIcon />
                                </IconButton>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
};

const ProductVariant = ({ productId }) => {
    const dispatch = useDispatch();
    const product = location.state?.product || {};
    const fetchedVariants = useSelector(state => state.products.productVariants[productId]?.data || []);
    const [variants, setVariants] = useState([]);
    const [selectedVariantIndexes, setSelectedVariantIndexes] = useState({});
    const [quantity, setQuantity] = useState(1);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [snackbar, setSnackbar] = useState({
        open: false,
        message: '',
        severity: 'success'
    });

    const handleSnackbarClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setSnackbar({ ...snackbar, open: false });
    };

    useEffect(() => {
        const fetchVariants = async () => {
            try {
                setLoading(true);
                await dispatch(fetchProductVariants(productId)).unwrap();
                setError(null);
            } catch (err) {
                console.error("Error fetching product variants:", err);
                setError("An error occurred while retrieving product variants. Please try again later.");
            } finally {
                setLoading(false);
            }
        };

        fetchVariants();
    }, [dispatch, productId]);

    useEffect(() => {
        // Convert fetched variants to the format used in this component
        const convertedVariants = fetchedVariants.reduce((acc, variant) => {
            const existingVariant = acc.find(v => v.optionName === variant.variant_name);
            if (existingVariant) {
                existingVariant.optionValues.push(variant.variant_value);
                existingVariant.prices.push(variant.price);
                existingVariant.ids.push(variant.id);  // Add this line
            } else {
                acc.push({
                    id: Date.now() + Math.random(),
                    optionName: variant.variant_name,
                    optionValues: [variant.variant_value],
                    prices: [variant.price],
                    ids: [variant.id]  // Add this line
                });
            }
            return acc;
        }, []);

        setVariants(convertedVariants);
    }, [fetchedVariants]);

    useEffect(() => {
        if (variants.length > 0) {
            const initialIndexes = variants.reduce((acc, variant, index) => {
                acc[index] = 0;
                return acc;
            }, {});
            setSelectedVariantIndexes(initialIndexes);
        }
    }, [variants]);

    const addVariant = () => {
        const newVariant = {
            id: Date.now(),
            optionName: '',
            optionValues: [''],
            prices: ['']
        };
        setVariants([...variants, newVariant]);
    };

    const updateVariant = (id, field, value) => {
        const newVariants = variants.map(variant =>
            variant.id === id ? { ...variant, [field]: value } : variant
        );
        setVariants(newVariants);
    };

    const updateVariantValue = (id, field, index, value) => {
        const newVariants = variants.map(variant => {
            if (variant.id === id) {
                const newValues = [...variant[field]];
                newValues[index] = value;
                return { ...variant, [field]: newValues };
            }
            return variant;
        });
        setVariants(newVariants);
    };

    const addOptionValue = (variantId) => {
        const newVariants = variants.map(variant => {
            if (variant.id === variantId) {
                return {
                    ...variant,
                    optionValues: [...variant.optionValues, ''],
                    prices: [...variant.prices, '']
                };
            }
            return variant;
        });
        setVariants(newVariants);
    };

    const removeVariant = (id) => {
        const newVariants = variants.filter(variant => variant.id !== id);
        setVariants(newVariants);
    };

    const removeOptionValue = (variantId, valueIndex) => {
        setVariants(prevVariants => prevVariants.map(variant => {
            if (variant.id === variantId) {
                return {
                    ...variant,
                    optionValues: variant.optionValues.filter((_, i) => i !== valueIndex),
                    prices: variant.prices.filter((_, i) => i !== valueIndex),
                    ids: variant.ids.filter((_, i) => i !== valueIndex)
                };
            }
            return variant;
        }));
    };

    const result = () => {
        const product_variants = variants.flatMap((variant, variantIndex) =>
            variant.optionValues.map((value, valueIndex) => ({
                variant_name: variant.optionName,
                variant_value: value,
                price: variant.prices[valueIndex] || "0.00",
            }))
        );

        return JSON.stringify({ product_variants }, null, 2);
    };

    const handleSave = async () => {
        setLoading(true);
        try {
            const variantsToSave = variants.flatMap(variant =>
                variant.optionValues.map((value, index) => ({
                    id: variant.ids[index],
                    variant_name: variant.optionName,
                    variant_value: value,
                    price: variant.prices[index] || "0.00",
                }))
            );

            let successCount = 0;
            let errorCount = 0;

            for (let variant of variantsToSave) {
                try {
                    if (variant.id) {
                        // If id exists, update the variant
                        await dispatch(updateProductVariant({
                            productId,
                            variantId: variant.id,
                            variantData: {
                                id: variant.id,
                                product_id: productId,
                                variant_name: variant.variant_name,
                                variant_value: variant.variant_value,
                                price: variant.price
                            }
                        })).unwrap();
                        successCount++;
                    } else {
                        // If no id, create a new variant
                        await dispatch(createProductVariant({
                            productId,
                            variantData: {
                                variant_name: variant.variant_name,
                                variant_value: variant.variant_value,
                                price: variant.price
                            }
                        })).unwrap();
                        successCount++;
                    }
                } catch (err) {
                    console.error("Error saving variant:", err);
                    errorCount++;
                }
            }

            // if (successCount > 0 && errorCount === 0) {
            //     setSnackbar({
            //         open: true,
            //         message: 'Business details saved successfully!',
            //         severity: 'success'
            //     });
            // } else if (successCount > 0 && errorCount > 0) {
            //     setSnackbarMessage(`${successCount} variants saved, ${errorCount} failed`);
            //     setSnackbarOpen(true);
            // } else {
            //     setSnackbarMessage('Failed to save variants');
            //     setSnackbarOpen(true);
            // }

            setSnackbar({
                open: true,
                message: 'Product variant saved successfully!',
                severity: 'success'
            });

            // Refresh the variants list
            await dispatch(fetchProductVariants(productId)).unwrap();

        } catch (err) {
            console.error("Error in save process:", err);
            setSnackbar({
                open: true,
                message: 'An error occurred while saving the variants. Please try again.',
                severity: 'error'
            });
        }
    };

    const handleQuantityChange = (amount) => {
        setQuantity(prevQuantity => Math.max(1, prevQuantity + amount));
    };

    const handleOptionSelect = (variantIndex, optionIndex) => {
        setSelectedVariantIndexes(prev => ({
            ...prev,
            [variantIndex]: optionIndex
        }));
    };

    const calculatePrice = () => {
        return variants.reduce((total, variant, index) => {
            const selectedOptionIndex = selectedVariantIndexes[index] || 0;
            return total + (parseFloat(variant.prices[selectedOptionIndex]) || 0);
        }, 0).toFixed(2);
    };

    // if (loading) {
    //     return <Typography>Loading variants...</Typography>;
    // }

    return (
        <Box sx={{ display: 'flex', maxWidth: '100%', padding: 2 }}>
            <Box sx={{ width: '50%', paddingRight: 2 }}>
                <Typography sx={{fontSize: '22px', fontWeight: '500', mb:1}}>
                    Variants
                </Typography>
                {error && (
                    <Alert severity="error" sx={{ mb: 2 }}>
                        {error}
                    </Alert>
                )}
                {variants.length === 0 && (
                    <Button
                        onClick={addVariant}
                        variant='save'
                        sx={{ marginBottom: 2 }}
                    >
                        + Add options like size or color
                    </Button>
                )}
                {variants.map((variant) => (
                    <Paper key={variant.id} elevation={3} sx={{ padding: 2, marginBottom: 2, borderRadius:'12px' }}>
                        <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: 2 }}>
                            <TextField
                                fullWidth
                                label="Option name"
                                placeholder="Enter option details (e.g., size, color, material)"
                                value={variant.optionName}
                                onChange={(e) => updateVariant(variant.id, 'optionName', e.target.value)}
                                sx={{ marginRight: 1 }}
                            />
                            <IconButton onClick={() => removeVariant(variant.id)}>
                                <DeleteIcon />
                            </IconButton>
                        </Box>
                        <VariantTable
                            variant={variant}
                            updateVariantValue={updateVariantValue}
                            ids={variant.ids}
                            removeOptionValue={removeOptionValue}
                        />
                        <Button
                            onClick={() => addOptionValue(variant.id)}
                            variant='cancel'
                            sx={{
                                width: '100%',
                                height: '42px',
                                justifyContent: 'flex-start',
                                textAlign: 'left',
                                fontWeight: '500',
                                fontSize: '16px',
                                marginBottom: '16px',
                                marginTop:'0px',
                            }}
                        >
                            Add another value
                        </Button>
                    </Paper>
                ))}
                {variants.length > 0 && (
                    <Box sx={{display:'flex'}}>
                        <Button
                            onClick={addVariant}
                            variant='save'
                            sx={{ marginTop: 2, marginRight: 2 }}
                        >
                            + Add another option
                        </Button>
                        <Button
                            onClick={handleSave}
                            variant='cancel'
                            disabled={loading}
                            sx={{ marginTop: 2 }}
                        >
                            {loading ? 'Saving...' : 'Save'}
                        </Button>
                    </Box>
                )}
            </Box>

            <Box sx={{ width: '50%', maxWidth:'700px', paddingLeft: 10 }}>
                <Paper elevation={3} sx={{ padding: 2, borderRadius: '12px' }}>
                    <Typography variant="h5" component="div" sx={{ fontWeight: 'bold', mb: 2 }}>
                        Test Product
                    </Typography>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Box sx={{ width: '100%', height: '200px', backgroundColor: '#f0f0f0', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                <Typography>Product Image Placeholder</Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="h6" component="div" sx={{ fontWeight: 'bold', mb: 2 }}>
                                RM {calculatePrice()}
                            </Typography>
                            {variants.map((variant, variantIndex) => (
                                <Box key={variant.id} sx={{ mb: 2 }}>
                                    <Typography variant="subtitle1" gutterBottom>
                                        {variant.optionName}
                                    </Typography>
                                    <ButtonGroup variant="outlined" aria-label="variant selection" sx={{ mb: 2, flexWrap: 'wrap' }}>
                                        {variant.optionValues.map((value, optionIndex) => (
                                            <Button
                                                key={optionIndex}
                                                variant={optionIndex === (selectedVariantIndexes[variantIndex] || 0) ? 'save' : 'cancel'}
                                                sx={{ borderRadius: '20px', m: 0.5 }}
                                                onClick={() => handleOptionSelect(variantIndex, optionIndex)}
                                            >
                                                {value}
                                            </Button>
                                        ))}
                                    </ButtonGroup>
                                </Box>
                            ))}
                            <Typography variant="subtitle1" gutterBottom>
                                Quantity
                            </Typography>
                            <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                                <Button variant="cancel" sx={{ minWidth: '40px' }} onClick={() => handleQuantityChange(-1)}>-</Button>
                                <TextField
                                    value={quantity}
                                    inputProps={{ style: { textAlign: 'center' } }}
                                    sx={{ mx: 1, width: '60px' }}
                                    onChange={(e) => setQuantity(Math.max(1, parseInt(e.target.value) || 1))}
                                />
                                <Button variant="cancel" sx={{ minWidth: '40px' }} onClick={() => handleQuantityChange(1)}>+</Button>
                            </Box>
                            <Button variant="save" fullWidth sx={{ mb: 2 }}>
                                Add to cart
                            </Button>
                        </Grid>
                    </Grid>
                </Paper>
            </Box>
            <Snackbar
                open={snackbar.open}
                autoHideDuration={6000}
                onClose={handleSnackbarClose}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            >
                <Alert onClose={handleSnackbarClose} severity={snackbar.severity} sx={{ width: '100%' }}>
                    {snackbar.message}
                </Alert>
            </Snackbar>
        </Box>
    );
};

export default ProductVariant;