import React from 'react';
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    TextField,
    FormControl,
    FormControlLabel,
    FormLabel,
    Radio,
    RadioGroup,
    Checkbox,
    Switch,
    Button,
    Box
} from '@mui/material';

const EditCouponDialog = ({ open, onClose, coupon, onSave, onChange }) => {
    if (!coupon) return null;

    return (
        <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
            <DialogTitle>Edit Coupon</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Edit the details of the selected coupon.
                </DialogContentText>
                <Box sx={{ mt: 2, display: 'flex', flexDirection: 'column', gap: 2 }}>
                    <TextField
                        fullWidth
                        name="name"
                        label="Name"
                        value={coupon.name}
                        onChange={onChange}
                    />
                    <FormControl component="fieldset">
                        <FormLabel component="legend">Discount Type</FormLabel>
                        <RadioGroup
                            name="discount_type"
                            value={coupon.discount_type}
                            onChange={onChange}
                        >
                            <FormControlLabel value="percentage" control={<Radio />} label="Percentage" />
                            <FormControlLabel value="fixed" control={<Radio />} label="Fixed Amount" />
                        </RadioGroup>
                    </FormControl>
                    <TextField
                        fullWidth
                        name="discount_value"
                        label="Discount Value"
                        type="number"
                        value={coupon.discount_value}
                        onChange={onChange}
                    />
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={coupon.apply_to_specific_products}
                                onChange={onChange}
                                name="apply_to_specific_products"
                            />
                        }
                        label="Apply to specific products"
                    />
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={coupon.limit_total_usage}
                                onChange={onChange}
                                name="limit_total_usage"
                            />
                        }
                        label="Limit total usage"
                    />
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={coupon.limit_date_range}
                                onChange={onChange}
                                name="limit_date_range"
                            />
                        }
                        label="Limit date range"
                    />
                    <FormControlLabel
                        control={
                            <Switch
                                checked={coupon.customer_facing_code}
                                onChange={onChange}
                                name="customer_facing_code"
                            />
                        }
                        label="Customer facing code"
                    />
                </Box>
            </DialogContent>
            <DialogActions>
                <Button variant='cancel' onClick={onClose}>Cancel</Button>
                <Button variant='save' onClick={onSave}>Save</Button>
            </DialogActions>
        </Dialog>
    );
};

export default EditCouponDialog;