import React, { useState } from 'react';
import { Box, Tabs, Tab, Divider, Typography } from '@mui/material';
import AddTicketingForm from "./AddTicketingForm";
import AddProductToTicketing from "./AddProductToTicketing";
import {useLocation} from "react-router-dom";
import AddEventImages from "./AddEventImages";

const AddTicketing = () => {
    const [value, setValue] = useState(0);
    const handleTabChange = (event, newValue) => {
        setValue(newValue);
    };
    const location = useLocation();
    const editEvent = location.state?.editEvent;
    return (
        <Box flex={1} sx={{padding:'32px'}}>
            <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-start', alignSelf: 'stretch', width: '100%'}}>
                <Box>
                    <Typography variant='h1'>
                        Add New Events
                    </Typography >
                    <Typography variant='h2'>
                        Fill out the details below to add a new event, customize event types, and set event availability.
                    </Typography>
                </Box>
                <Box sx={{ ml: 'auto', display: 'flex', gap: '12px' }}>
                    {/*for buttons*/}
                </Box>
            </Box>

            <Box height='38px' paddingTop='32px'>
                <Tabs value={value} onChange={handleTabChange} aria-label="service tabs">
                    <Tab label={editEvent ? "Edit event" : "Add new event"} />
                    <Tab label="Add product to event" />
                    <Tab label="Add Event Images" />
                </Tabs>
            </Box>

            <Divider sx={{margin: '41px 0 32px 0'}}/>

            <Box>
                { value === 0 && <AddTicketingForm editEvent={editEvent} /> }
                { value === 1 && <AddProductToTicketing/> }
                { value === 2 && <AddEventImages/> }
            </Box>
        </Box>
    );
};

export default AddTicketing;
