import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider } from '@mui/material/styles';
import { Provider } from 'react-redux';
import App from './App';
import theme from './theme';
import keycloak from './keycloak'; // Ensure this path is correct
import ErrorBoundary from './components/ErrorBoundary';
import { store } from './redux/store';
import { Helmet } from "react-helmet";

const container = document.getElementById('root');
const root = ReactDOM.createRoot(container);

const renderApp = () => {
    root.render(
        <ErrorBoundary>
            <Helmet>
                <meta name="robots" content="noindex, nofollow" />
            </Helmet>
            <React.StrictMode>
                <ThemeProvider theme={theme}>
                    <Provider store={store}>
                        <BrowserRouter>
                            <App />
                        </BrowserRouter>
                    </Provider>
                </ThemeProvider>
            </React.StrictMode>
        </ErrorBoundary>
    );
};

keycloak.init({
    onLoad: 'check-sso',
    silentCheckSsoRedirectUri: window.location.origin + '/silent-check-sso.html',
    pkceMethod: 'S256',
}).then((authenticated) => {
    if (authenticated) {
        console.log('User is authenticated');
    } else {
        console.log('User is not authenticated');
    }
    renderApp();
}).catch((error) => {
    console.error('Failed to initialize Keycloak', error);
    renderApp();
});