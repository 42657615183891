import React, {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import { useDispatch } from 'react-redux';
import { createEvent, updateEvent } from '../../../redux/eventsSlice';
import {
    Box,
    Button,
    Divider,
    FormControl,
    FormControlLabel,
    InputLabel,
    MenuItem,
    Select,
    Switch,
    TextField,
    Typography,
    Snackbar,
    Alert,
    CircularProgress,
    Checkbox,
    FormGroup,
} from "@mui/material";
import { styled } from "@mui/system";
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { isWeekend } from 'date-fns';

const TextFieldTitle = styled(Typography)(({ theme }) => ({
    color: 'var(--Gray-700, #344054)',
    fontFamily: 'Inter',
    fontSize: '12px',
    fontStyle: 'normal',
    fontWeight: 700,
    lineHeight: '20px',
    width: '312px',
}));

const AddTicketingForm = ({ editEvent }) => {
    console.info('editEvent', editEvent);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [eventData, setEventData] = useState({
        name: '',
        description: '',
        event_start_date: '',
        event_end_date: '',
        event_type_id: '',
        status: true,
        daily_events: false,
        minimum_pax: '',
        maximum_pax: ''
    });
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState('success');
    const [loading, setLoading] = useState(false);
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [scheduleStart, setScheduleStart] = useState(null);
    const [scheduleEnd, setScheduleEnd] = useState(null);
    const [weekendsOnly, setWeekendsOnly] = useState(false);
    const [useDisabledDays, setUseDisabledDays] = useState(false);
    const [disabledDays, setDisabledDays] = useState({
        0: false, // Sunday
        1: true,  // Monday
        2: false, // Tuesday
        3: true,  // Wednesday
        4: false, // Thursday
        5: true,  // Friday
        6: false  // Saturday
    });

    useEffect(() => {
        if (editEvent) {
            console.info('editEvent');
            setEventData(editEvent);
        }
    }, [editEvent]);

    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        setEventData(prev => ({
            ...prev,
            [name]: type === 'checkbox' ? checked : value
        }));
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        setLoading(true);
        try {
            let resultAction;
            if (editEvent) {
                resultAction = await dispatch(updateEvent(eventData));
            } else {
                resultAction = await dispatch(createEvent(eventData));
            }

            if ((editEvent ? updateEvent.fulfilled : createEvent.fulfilled).match(resultAction)) {
                setSnackbarMessage(editEvent ? "Event updated successfully!" : "Event created successfully!");
                setSnackbarSeverity("success");
                setSnackbarOpen(true);

                if (!editEvent) {
                    // Reset form fields only for new events
                    setEventData({
                        name: '',
                        description: '',
                        event_start_date: '',
                        event_end_date: '',
                        event_type: '',
                        status: true,
                        daily_events: false,
                        minimum_pax: '',
                        maximum_pax: ''
                    });
                }

                setTimeout(() => {
                    navigate('/events');
                }, 1500); // Delay for 1.5 seconds so the user can see the success message
            } else {
                throw new Error(resultAction.error.message || `Failed to ${editEvent ? 'update' : 'create'} event`);
            }
        } catch (error) {
            setSnackbarMessage(error.message || `Failed to ${editEvent ? 'update' : 'create'} event`);
            setSnackbarSeverity("error");
            setSnackbarOpen(true);
        } finally {
            setLoading(false);
        }
    };

    const handleSnackbarClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setSnackbarOpen(false);
    };

    const handleWeekendToggle = (event) => {
        setWeekendsOnly(event.target.checked);
        setStartDate(null);
        setEndDate(null);
    };

    const handleDisabledDaysToggle = (event) => {
        setUseDisabledDays(event.target.checked);
        setStartDate(null);
        setEndDate(null);
    };

    const handleDayToggle = (day) => (event) => {
        setDisabledDays(prev => ({ ...prev, [day]: event.target.checked }));
        setStartDate(null);
        setEndDate(null);
    };

    const shouldDisableDate = (date) => {
        if (weekendsOnly && !isWeekend(date)) {
            return true;
        }
        if (useDisabledDays) {
            return disabledDays[date.getDay()];
        }
        return false;
    };

    return (
        <Box>
            <form noValidate autoComplete="off" onSubmit={handleSubmit}>
                {/* Event Name Field */}
                <Box sx={{ display: 'flex', gap: '10px', marginY: 2 }}>
                    <TextFieldTitle>Event Name</TextFieldTitle>
                    <TextField
                        name="name"
                        value={eventData.name}
                        onChange={handleChange}
                        fullWidth
                        placeholder='Event name'
                        sx={{paddingRight: { md: '50px', lg: '240px' }}}
                    />
                </Box>

                <Divider />

                {/* Description Field */}
                <Box sx={{ display: 'flex', gap: '10px', marginY: 2 }}>
                    <TextFieldTitle>Description</TextFieldTitle>
                    <TextField
                        name="description"
                        value={eventData.description}
                        onChange={handleChange}
                        fullWidth
                        multiline
                        rows={4}
                        placeholder="Enter event description"
                        sx={{
                            '& .MuiOutlinedInput-root': {
                                padding: '15px',
                                '& .MuiOutlinedInput-input': {
                                    padding: 0,
                                },
                            },
                            paddingRight: { md: '50px', lg: '240px' }
                        }}
                    />
                </Box>

                <Divider />

                {/* Start Date and End Date Fields */}
                <Box sx={{ display: 'flex', gap: '10px', marginY: 2 }}>
                    <TextFieldTitle>Start Date - End Date</TextFieldTitle>
                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: '20px', width: '100%', paddingRight: { md: '50px', lg: '240px'} }}>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <Box sx={{ display: 'flex', gap: '20px' }}>
                                <DatePicker
                                    label="Start Date"
                                    value={startDate}
                                    onChange={(newValue) => setStartDate(newValue)}
                                    shouldDisableDate={shouldDisableDate}
                                    sx={{ flex: 1 }}
                                />
                                <DatePicker
                                    label="End Date"
                                    value={endDate}
                                    onChange={(newValue) => setEndDate(newValue)}
                                    shouldDisableDate={shouldDisableDate}
                                    sx={{ flex: 1 }}
                                />
                                <Box sx={{ flex: 2 }}/>
                            </Box>
                        </LocalizationProvider>
                        <FormControlLabel
                            control={
                                <Switch
                                    checked={weekendsOnly}
                                    onChange={handleWeekendToggle}
                                    name="weekendsOnly"
                                />
                            }
                            label="Weekends Only"
                        />
                        <FormControlLabel
                            control={
                                <Switch
                                    checked={useDisabledDays}
                                    onChange={handleDisabledDaysToggle}
                                    name="useDisabledDays"
                                />
                            }
                            label="Optional Days"
                        />
                        {useDisabledDays && (
                            <>
                                <Typography sx={{fontSize:'12px'}}>example</Typography>
                                <FormGroup row>
                                    {['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'].map((day, index) => (
                                        <FormControlLabel
                                            key={day}
                                            control={
                                                <Checkbox
                                                    checked={disabledDays[index]}
                                                    onChange={handleDayToggle(index)}
                                                    name={day}
                                                />
                                            }
                                            label={day}
                                        />
                                    ))}
                                </FormGroup>
                            </>
                        )}
                    </Box>
                </Box>

                <Divider />

                {/* Min-Max Capacity Fields */}
                <Box sx={{ display: 'flex', gap: '10px', marginY: 2 }}>
                    <TextFieldTitle>Min - Max Capacity(Pax) per day</TextFieldTitle>
                    <Box sx={{ display: 'flex', gap: '20px', width: '100%', paddingRight: { md: '50px', lg: '240px'} }}>
                        <TextField
                            type="number"
                            name="minimum_pax"
                            value={eventData.minimum_pax}
                            onChange={handleChange}
                            fullWidth
                            InputLabelProps={{
                                shrink: true,
                            }}
                            sx={{ flex: 1 }}
                        />
                        <TextField
                            type="number"
                            name="maximum_pax"
                            value={eventData.maximum_pax}
                            onChange={handleChange}
                            fullWidth
                            InputLabelProps={{
                                shrink: true,
                            }}
                            sx={{ flex: 1 }}
                        />
                        <Box sx={{ flex: 2 }}/>
                    </Box>
                </Box>

                <Divider />

                {/*Schedule of live sales*/}
                <Box sx={{ display: 'flex', gap: '10px', marginY: 2 }}>
                    <TextFieldTitle>Schedule of live sales</TextFieldTitle>
                    <Box sx={{ display: 'flex', gap: '20px', width: '100%', paddingRight: { md: '50px', lg: '240px'} }}>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <DatePicker
                                label="Start Date"
                                value={scheduleStart}
                                onChange={(newValue) => setScheduleStart(newValue)}
                                sx={{ flex: 1 }}
                            />
                            <DatePicker
                                label="End Date"
                                value={scheduleEnd}
                                onChange={(newValue) => setScheduleEnd(newValue)}
                                sx={{ flex: 1 }}
                            />
                            <Box sx={{ flex: 2 }}/>
                        </LocalizationProvider>
                    </Box>
                </Box>

                <Divider/>

                {/* Event Type Field */}
                <Box sx={{ display: 'flex', gap: '10px', marginY: 2 }}>
                    <TextFieldTitle>Event Type</TextFieldTitle>
                    <FormControl fullWidth sx={{ paddingRight: { md: '50px', lg: '240px' } }}>
                        <InputLabel>Event Type</InputLabel>
                        <Select
                            name="event_type_id"
                            value={eventData.event_type_id}
                            onChange={handleChange}
                            label="Event Type"
                        >
                            <MenuItem value="5">Buffet</MenuItem>
                            <MenuItem value="6">Birthday</MenuItem>
                            <MenuItem value="7">Wedding</MenuItem>
                            <MenuItem value="9">Team Building</MenuItem>
                        </Select>
                    </FormControl>
                </Box>

                <Divider />

                {/* Status Field */}
                <Box sx={{ display: 'flex', gap: '10px', marginY: 2, alignItems: 'center' }}>
                    <TextFieldTitle>Status</TextFieldTitle>
                    <Box sx={{ paddingRight: { md: '50px', lg: '240px' }, width: '100%' }}>
                        <FormControlLabel
                            control={
                                <Switch
                                    checked={eventData.status}
                                    onChange={handleChange}
                                    name="status"
                                />
                            }
                            label=''
                        />
                    </Box>
                </Box>

                <Divider />

                {/* Daily Events Field */}
                <Box sx={{ display: 'flex', gap: '10px', marginY: 2, alignItems: 'center' }}>
                    <TextFieldTitle>Daily Events</TextFieldTitle>
                    <Box sx={{ paddingRight: { md: '50px', lg: '240px' }, width: '100%' }}>
                        <FormControlLabel
                            control={
                                <Switch
                                    checked={eventData.daily_events}
                                    onChange={handleChange}
                                    name="daily_events"
                                />
                            }
                            label=''
                        />
                    </Box>
                </Box>

                <Box sx={{ display: 'flex', justifyContent: "flex-end", gap: '8px', paddingTop: '32px' }}>
                    <Button variant='cancel' type="button">Cancel</Button>
                    <Button variant='save' type="submit" disabled={loading}>
                        {loading ? <CircularProgress size={24} /> : (editEvent ? 'Update' : 'Save')}
                    </Button>
                </Box>
            </form>

            <Snackbar
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                open={snackbarOpen}
                autoHideDuration={5000}
                onClose={handleSnackbarClose}
            >
                <Alert
                    onClose={handleSnackbarClose}
                    severity={snackbarSeverity}
                    sx={{ width: '100%' }}
                    iconMapping={{
                        success: <CheckCircleOutlineIcon />,
                        error: <ErrorOutlineIcon />,
                    }}
                >
                    {snackbarMessage}
                </Alert>
            </Snackbar>
        </Box>
    );
};

export default AddTicketingForm;