import React, { useState } from 'react';
import {Link, useNavigate} from 'react-router-dom';

import {Button, Box, Tabs, Tab, Divider, Typography} from '@mui/material';
import {Add as AddIcon} from "@mui/icons-material";
import Active from "./Active";
import Incoming from "./Incoming";
import Past from "./Past";

const Appointments = () => {
    const [value, setValue] = useState(0);
    const handleTabChange = (event, newValue) => {
        setValue(newValue);
    };

    const navigate = useNavigate();
    const handleClick = () => {
        navigate('add-appointments');
    };

    return (

        <Box sx={{ display:'flex', padding:{xs:1,md:'32px'} }}>
            <Box flex={1}>
                <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-start', alignSelf: 'stretch', width: '100%'}}>
                    <Box>
                        <Typography variant='h1'>
                            Appointments
                        </Typography >
                        <Typography variant='h2'>
                            Manage your appointments and schedules.
                        </Typography>
                    </Box>
                    <Box sx={{ ml: 'auto', mr:'32px', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                        <Button variant="save" color="primary" startIcon={<AddIcon />} onClick={handleClick}>
                            Add New Appointments
                        </Button>
                    </Box>
                </Box>

                <Box height='38px' paddingTop='32px'>
                    <Tabs value={value} onChange={handleTabChange} aria-label="service tabs">
                        <Tab label="Active" />
                        <Tab label="Incoming" />
                        <Tab label="Past" />
                    </Tabs>
                </Box>

                <Divider sx={{margin: '41px 0 32px 0'}}/>

                <Box>
                    { value === 0 && <Active/> }
                    { value === 1 && <Incoming/> }
                    { value === 2 &&  <Past/> }
                </Box>
            </Box>
        </Box>

    );
};

export default Appointments;
