import React, { useEffect, useState } from 'react';
import {
    Box,
    Paper,
    Typography,
    TextField,
    Divider,
    CircularProgress,
    IconButton,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Button
} from '@mui/material';
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { fetchShippingRates, updateShippingRate, deleteShippingRate } from './ShippingRateAPI';
import { DeleteIcon, EditIcon } from "../../../shared/IconButtons";
import EditShippingRateDialog from './EditShippingRateDialog';

const ShippingRatesList = () => {
    const [shippingRates, setShippingRates] = useState([]);
    const [loading, setLoading] = useState(true);
    const [searchQuery, setSearchQuery] = useState('');
    const [paginationModel, setPaginationModel] = useState({
        page: 0,
        pageSize: 10
    });
    const [totalCount, setTotalCount] = useState(0);
    const [editDialogOpen, setEditDialogOpen] = useState(false);
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
    const [selectedShippingRate, setSelectedShippingRate] = useState(null);
    const [editedShippingRate, setEditedShippingRate] = useState(null);

    const loadShippingRates = async () => {
        setLoading(true);
        try {
            const data = await fetchShippingRates(paginationModel.page, paginationModel.pageSize, searchQuery);
            setShippingRates(data.data.shippingRates);
            setTotalCount(data.data.pagination.totalCount || 0);
        } catch (error) {
            console.error('Error loading shipping rates:', error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        loadShippingRates();
    }, [paginationModel, searchQuery]);

    const handleSearchChange = (event) => {
        setSearchQuery(event.target.value);
        setPaginationModel({ ...paginationModel, page: 0 });
    };

    const handlePaginationModelChange = (newModel) => {
        setPaginationModel(newModel);
    };

    const handleEditClick = (shippingRate) => {
        setSelectedShippingRate(shippingRate);
        setEditedShippingRate({ ...shippingRate });
        setEditDialogOpen(true);
    };

    const handleDeleteClick = (shippingRate) => {
        setSelectedShippingRate(shippingRate);
        setDeleteDialogOpen(true);
    };

    const handleEditInputChange = (event) => {
        const { name, value } = event.target;
        setEditedShippingRate(prev => ({
            ...prev,
            [name]: value
        }));
    };

    const handleEditConfirm = async () => {
        try {
            await updateShippingRate(editedShippingRate.id, editedShippingRate);
            loadShippingRates();
            setEditDialogOpen(false);
        } catch (error) {
            console.error('Error updating shipping rate:', error);
        }
    };

    const handleDeleteConfirm = async () => {
        try {
            await deleteShippingRate(selectedShippingRate.id);
            loadShippingRates();
            setDeleteDialogOpen(false);
        } catch (error) {
            console.error('Error deleting shipping rate:', error);
        }
    };

    const columns = [
        {
            field: 'id',
            headerName: 'ID',
            flex: 0.3,
            renderCell: (params) => (
                <Box sx={{ display: 'flex', alignItems: 'center', height: '100%' }}>
                    <Typography variant="body2">{params.value}</Typography>
                </Box>
            )
        },
        {
            field: 'amount',
            headerName: 'Amount',
            flex: 0.7,
            renderCell: (params) => (
                <Box sx={{ display: 'flex', alignItems: 'center', height: '100%' }}>
                    <Typography variant="body2">{params.value}</Typography>
                </Box>
            )
        },
        {
            field: 'currency_id',
            headerName: 'Currency ID',
            flex: 0.6,
            renderCell: (params) => (
                <Box sx={{ display: 'flex', alignItems: 'center', height: '100%' }}>
                    <Typography variant="body2">{params.value}</Typography>
                </Box>
            )
        },
        {
            field: 'description',
            headerName: 'Description',
            flex: 1,
            renderCell: (params) => (
                <Box sx={{ display: 'flex', alignItems: 'center', height: '100%' }}>
                    <Typography variant="body2">{params.value}</Typography>
                </Box>
            )
        },
        {
            field: 'estimated_shipping_time_min',
            headerName: 'Min Shipping Time',
            flex: 0.6,
            renderCell: (params) => (
                <Box sx={{ display: 'flex', alignItems: 'center', height: '100%' }}>
                    <Typography variant="body2">{params.value}</Typography>
                </Box>
            )
        },
        {
            field: 'estimated_shipping_time_max',
            headerName: 'Max Shipping Time',
            flex: 0.6,
            renderCell: (params) => (
                <Box sx={{ display: 'flex', alignItems: 'center', height: '100%' }}>
                    <Typography variant="body2">{params.value}</Typography>
                </Box>
            )
        },
        {
            field: 'actions',
            headerName: '',
            flex: 0.7,
            renderCell: (params) => (
                <Box sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', height: '100%', width: '100%' }}>
                    <IconButton aria-label="edit" onClick={() => handleEditClick(params.row)}>
                        <EditIcon/>
                    </IconButton>
                    <IconButton aria-label="delete" onClick={() => handleDeleteClick(params.row)}>
                        <DeleteIcon/>
                    </IconButton>
                </Box>
            ),
        },
    ];

    return (
        <Box sx={{padding:'32px'}}>
            <Box>
                <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
                    <Box>
                        <Typography variant='h1'>Shipping Rates</Typography>
                        <Typography variant='h2'>Manage delivery costs and options to optimize your global logistics.</Typography>
                    </Box>
                    <Box>
                        {/*<Button component={Link} to="/coupons/add-coupon" variant="save" startIcon={<AddIcon />}>*/}
                        {/*    Add New Coupon*/}
                        {/*</Button>*/}
                    </Box>
                </Box>
                <Divider/>

                <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
                    <Box/>
                    <Box display="flex" gap="12px">
                        <TextField
                            variant="outlined"
                            placeholder="Search shipping rates"
                            value={searchQuery}
                            onChange={handleSearchChange}
                            size="small"
                            sx={{
                                '& .MuiOutlinedInput-root': {
                                    height: '34px',
                                    '& input': {
                                        height: '34px',
                                        padding: '0 14px',
                                    },
                                    '& .MuiInputBase-input': {
                                        height: '34px',
                                        padding: '0 14px',
                                    }
                                }
                            }}
                        />
                    </Box>
                </Box>

                <Paper
                    sx={{
                        width: "100%",
                        borderRadius: "8px",
                        marginTop: '24px',
                        border: "1px solid var(--Gray-200, #EAECF0)",
                        background: "var(--White, #FFF)",
                        boxShadow: "0px 1px 3px 0px rgba(16, 24, 40, 0.10), 0px 1px 2px 0px rgba(16, 24, 40, 0.06)",
                    }}
                >
                    <Typography
                        sx={{
                            color: "var(--Gray-900, #101828)",
                            fontFamily: "Inter",
                            fontSize: "18px",
                            fontStyle: "normal",
                            fontWeight: 500,
                            lineHeight: "28px",
                            padding: "20px 24px",
                        }}
                    >
                        All Shipping Rates
                    </Typography>

                    {loading ? (
                        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '200px' }}>
                            <CircularProgress />
                        </Box>
                    ) : (
                        <DataGrid
                            slots={{ toolbar: GridToolbar }}
                            rows={shippingRates}
                            columns={columns}
                            paginationModel={paginationModel}
                            onPaginationModelChange={handlePaginationModelChange}
                            pageSizeOptions={[5, 10, 20]}
                            rowCount={totalCount}
                            paginationMode="server"
                            checkboxSelection
                            disableRowSelectionOnClick
                            rowHeight={70}
                            loading={loading}
                            initialState={{
                                pagination: {
                                    paginationModel: {
                                        pageSize: 10,
                                    },
                                },
                            }}
                            sx={{
                                '& .MuiDataGrid-toolbarContainer .MuiButtonBase-root': {
                                    backgroundColor: '#737373',
                                    color: '#fff',
                                    height: '34px',
                                    my:1,
                                    minWidth: 'auto',
                                    '&:hover': {
                                        backgroundColor: '#404040',
                                    },
                                },
                            }}
                        />
                    )}
                </Paper>
            </Box>


            {/* Edit Dialog */}
            <EditShippingRateDialog
                open={editDialogOpen}
                onClose={() => setEditDialogOpen(false)}
                shippingRate={editedShippingRate}
                onChange={handleEditInputChange}
                onSave={handleEditConfirm}
            />

            {/* Delete Dialog */}
            <Dialog open={deleteDialogOpen} onClose={() => setDeleteDialogOpen(false)}>
                <DialogTitle>Delete Shipping Rate</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Are you sure you want to delete this shipping rate? This action cannot be undone.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button variant='cancel' onClick={() => setDeleteDialogOpen(false)}>Cancel</Button>
                    <Button variant='save' onClick={handleDeleteConfirm} color="error">Delete</Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
};

export default ShippingRatesList;