import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { Box, IconButton, Paper, Typography } from '@mui/material';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import keycloak from "../../../../keycloak";

const images = [
    "/1.jpeg",
    "/2.jpeg",
    "/3.jpeg",
    "/4.jpeg",
    "/5.jpeg",
    "/6.jpeg",
];

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
const PLACEHOLDER_IMAGE = images[0]; // Replace with your actual placeholder image path

const ProductDetails = () => {
    const { id } = useParams();
    const [product, setProduct] = useState(null);
    const [currentImageIndex, setCurrentImageIndex] = useState(0);

    useEffect(() => {
        const fetchProduct = async () => {
            try {
                const response = await axios.get(`${API_BASE_URL}/products?id=${id}`, {
                    headers: {
                        'Authorization': `Bearer ${keycloak.token}`
                    }
                });
                setProduct(response.data.data);
            } catch (error) {
                console.error("Error fetching product:", error);
            }
        };

        fetchProduct();
    }, [id, keycloak.token]);

    const handlePrevImage = () => {
        setCurrentImageIndex((prevIndex) =>
            prevIndex === 0 ? product.product_images.length - 1 : prevIndex - 1
        );
    };

    const handleNextImage = () => {
        setCurrentImageIndex((prevIndex) =>
            prevIndex === product.product_images.length - 1 ? 0 : prevIndex + 1
        );
    };

    const getCurrentImage = () => {
        if (product && product.product_images && product.product_images.length > 0) {
            return product.product_images[currentImageIndex].image_url;
        }
        return PLACEHOLDER_IMAGE;
    };

    return (
        <Box>
            <Paper
                sx={{
                    display: 'flex',
                    flexDirection: { xs: 'column', md: 'row' },
                    padding: '32px',
                    alignItems: { xs: 'center', md: 'flex-start' },
                    justifyContent:'center',
                    borderRadius:'12px',
                    elevation:1,
                    width: 'fit-content',
                    margin: 'auto',
                }}
            >
                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <Box
                        component="img"
                        sx={{
                            width: { xs: '320px', md: '500px' },
                            height: 'auto',
                            borderRadius: '8px',
                            marginBottom: '16px',
                        }}
                        alt="Product Image"
                        src={getCurrentImage()}
                    />
                    {product && product.product_images && product.product_images.length > 1 && (
                        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%' }}>
                            <IconButton onClick={handlePrevImage}>
                                <ArrowBackIosIcon />
                            </IconButton>
                            <Typography>
                                {currentImageIndex + 1} / {product.product_images.length}
                            </Typography>
                            <IconButton onClick={handleNextImage}>
                                <ArrowForwardIosIcon />
                            </IconButton>
                        </Box>
                    )}
                </Box>
                <Box sx={{ marginLeft: { xs: 0, md: '48px' }, marginTop: { xs: 5, md: 0 }, textAlign: { xs: 'center', md: 'left' } }}>
                    <Typography variant="h1" component="h1">
                        {product?.name}
                    </Typography>
                    <Typography variant="h2" >
                        {product?.description}
                    </Typography>
                    <Typography variant="h2">
                        Price: RM {product?.price}
                    </Typography>
                    {product?.variants && product.variants.length > 0 && (
                        <Box sx={{ marginTop: 2 }}>
                            <Typography variant="h3">Variants:</Typography>
                            {product.variants.map((variant, index) => (
                                <Typography key={index} variant="body1">
                                    {variant.variant_name}: {variant.variant_value} - ${variant.price}
                                </Typography>
                            ))}
                        </Box>
                    )}
                </Box>
            </Paper>
        </Box>
    );
};

export default ProductDetails;