import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    Box,
    Button,
    IconButton,
    LinearProgress,
    Typography,
    Paper,
    ImageList,
    ImageListItem,
    Alert,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    CircularProgress,
    Skeleton,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import ImageIcon from '@mui/icons-material/Image';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import DescriptionIcon from '@mui/icons-material/Description';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import AddFileIcon from "../shared/AddFileIcon";
import { fetchUploads, addUpload, deleteUpload } from '../../redux/uploadSlice';
import { PutObjectCommand, S3Client } from "@aws-sdk/client-s3";
import { v4 as uuidv4 } from 'uuid';

const s3Client = new S3Client({
    region: "ap-southeast-1",
    credentials: {
        accessKeyId: "AKIA5FTZAZQQCCRKEWU7",
        secretAccessKey: "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",
    },
});

const BASE_URL = 'https://ev-merchants.s3.ap-southeast-1.amazonaws.com/';

const Verification = () => {
    const dispatch = useDispatch();
    const { uploads, error, status } = useSelector((state) => state.uploads);
    const [files, setFiles] = useState([]);
    const [uploading, setUploading] = useState(false);
    const [uploadProgress, setUploadProgress] = useState({});
    const [confirmDialog, setConfirmDialog] = useState({ open: false, id: null });

    useEffect(() => {
        dispatch(fetchUploads());
    }, [dispatch]);

    useEffect(() => {
        console.log('Uploads state:', uploads);
    }, [uploads]);

    const handleFileUpload = async (event, fileType) => {
        setUploading(true);
        const newFiles = Array.from(event.target.files);
        setFiles((prevFiles) => [...prevFiles, ...newFiles]);
        const organizationId = 1;

        for (const file of newFiles) {
            try {
                const timestamp = Date.now();
                const uniqueId = uuidv4().slice(0, 6);

                const fileNameParts = file.name.split('.');
                const fileExtension = fileNameParts.pop();
                const fileName = fileNameParts.join('.');

                const uniqueFileName = `${fileName}_${timestamp}_${uniqueId}.${fileExtension}`;
                const key = `${organizationId}/${uniqueFileName}`;

                const params = {
                    Bucket: "ev-merchants",
                    Key: key,
                    Body: file,
                    ContentType: file.type,
                };

                const response = await s3Client.send(new PutObjectCommand(params));

                console.info('S3 upload response:', response);

                const fileUrl = `${BASE_URL}${encodeURIComponent(key)}`;

                const uploadInfo = {
                    key: key,
                    eTag: response.ETag,
                    versionId: response.VersionId,
                    url: fileUrl,
                    originalFilename: file.name,
                    uniqueFilename: uniqueFileName,
                    size: file.size,
                    type: file.type,
                    fileType: fileType,
                };

                console.info('Upload info:', uploadInfo);

                await dispatch(addUpload({ uploadInfo })).unwrap();

                setUploadProgress((prevProgress) => ({
                    ...prevProgress,
                    [file.name]: 100,
                }));
            } catch (err) {
                console.error("Error uploading file:", err);
                setUploadProgress((prevProgress) => ({
                    ...prevProgress,
                    [file.name]: 0,
                }));
            }
        }
        setUploading(false);
    };

    const handleDelete = (uploadId) => {
        setConfirmDialog({ open: true, id: uploadId });
    };

    const handleConfirmDelete = async () => {
        try {
            await dispatch(deleteUpload(confirmDialog.id)).unwrap();
        } catch (err) {
            console.error("Error deleting upload:", err);
        } finally {
            setConfirmDialog({ open: false, id: null });
        }
    };

    const renderFileIcon = (fileName) => {
        const extension = fileName.toLowerCase().split('.').pop();
        if (['jpg', 'jpeg', 'png', 'gif'].includes(extension)) {
            return <ImageIcon />;
        } else if (extension === 'pdf') {
            return <PictureAsPdfIcon />;
        } else if (['doc', 'docx'].includes(extension)) {
            return <DescriptionIcon />;
        } else {
            return <InsertDriveFileIcon />;
        }
    };

    const renderFileList = () => {
        if (status === 'loading') {
            return (
                <ImageList sx={{width: '100%', height: 'auto', marginTop: 2}} cols={3} rowHeight={164}>
                    {[...Array(6)].map((_, index) => (
                        <ImageListItem key={index}>
                            <Skeleton variant="rectangular" width="100%" height={164} />
                        </ImageListItem>
                    ))}
                </ImageList>
            );
        }

        if (status === 'failed') {
            return <Alert severity="error" sx={{ mt: 2 }}>{error}</Alert>;
        }

        if (Array.isArray(uploads) && uploads.length > 0) {
            return (
                <ImageList sx={{width: '100%', height: 'auto', marginTop: 2}} cols={3} rowHeight={164}>
                    {uploads.map((upload) => (
                        <ImageListItem key={upload.id}>
                            {upload.contentType && upload.contentType.startsWith('image/') ? (
                                <img
                                    src={`${BASE_URL}${upload.s3Key}`}
                                    alt={upload.filename}
                                    loading="lazy"
                                />
                            ) : (
                                <Box
                                    sx={{
                                        width: '100%',
                                        height: '200px',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        backgroundColor: '#f0f0f0',
                                    }}
                                >
                                    {renderFileIcon(upload.filename)}
                                </Box>
                            )}
                            <IconButton
                                sx={{position: 'absolute', top: 5, right: 5, bgcolor: 'background.paper'}}
                                onClick={() => handleDelete(upload.id)}
                            >
                                <DeleteIcon/>
                            </IconButton>
                        </ImageListItem>
                    ))}
                </ImageList>
            );
        }

        return null;
    };

    const renderUploadSection = (title, description, buttonText, acceptedFormats, fileType) => (
        <Paper elevation={3} sx={{ padding: 1, borderRadius: 2, mb: 2 }}>
            <Box display="flex" sx={{ backgroundColor: '#f4f6f8' }}>
                <Box padding="24px">
                    <AddFileIcon />
                </Box>
                <Box sx={{ flexGrow: 1 }}>
                    <Typography
                        sx={{
                            color: 'var(--dark-500, #09090B)',
                            fontFamily: 'Inter',
                            fontSize: '14px',
                            fontStyle: 'normal',
                            fontWeight: 600,
                            lineHeight: '20px',
                            paddingTop: '24px',
                        }}
                    >
                        {title}
                    </Typography>
                    <Typography
                        sx={{
                            color: 'var(--Gray-500, #667085)',
                            fontFamily: 'Inter',
                            fontSize: '12px',
                            fontStyle: 'normal',
                            fontWeight: 400,
                            lineHeight: '18px',
                        }}
                    >
                        {description}
                    </Typography>
                </Box>
                <Box sx={{ paddingTop: '24px', paddingRight: '24px' }}>
                    <Button
                        variant='cancel'
                        onClick={() => document.getElementById(`file-upload-input-${fileType}`).click()}
                    >
                        {buttonText}
                    </Button>
                    <input
                        id={`file-upload-input-${fileType}`}
                        type="file"
                        hidden
                        multiple
                        accept={acceptedFormats}
                        onChange={(e) => handleFileUpload(e, fileType)}
                    />
                </Box>
            </Box>
            {renderFileList()}
        </Paper>
    );

    return (
        <Box>
            {renderUploadSection(
                "Biz Owner Selfie with ID",
                "Upload a selfie with your ID. Acceptable formats are JPG and PNG.",
                "+ Add Image",
                ".jpg,.jpeg,.png",
                "selfie"
            )}
            {renderUploadSection(
                "Company SSM Documents",
                "Upload the SSM documents for your company. Acceptable formats are PDF, DOC, and DOCX.",
                "+ Add Document",
                ".pdf,.doc,.docx",
                "ssm"
            )}

            {uploading && <LinearProgress />}

            <Dialog
                open={confirmDialog.open}
                onClose={() => setConfirmDialog({ ...confirmDialog, open: false })}
            >
                <DialogTitle>Confirm Deletion</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Are you sure you want to delete this file? This action cannot be undone.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setConfirmDialog({ ...confirmDialog, open: false })} variant="cancel">Cancel</Button>
                    <Button onClick={handleConfirmDelete} variant="save" autoFocus>
                        {status === 'loading' ? <CircularProgress size={24} /> : 'Confirm'}
                    </Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
};

export default Verification;